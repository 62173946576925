import { createApi } from "@reduxjs/toolkit/query/react";

import config from "@/utils/config";
import { createBaseQuery } from "@/lib";
import { methods } from "@/utils/constants";
import cacher from "@/utils/rtkQueryCacheUtils";

export const tagType = "BANK";

const bankApi = createApi({
    baseQuery: createBaseQuery({ baseURL: `${config.apiURL}/data/banks` }),
    tagTypes: [cacher.defaultTags, tagType],
    reducerPath: "bankApi",
    endpoints: (builder) => ({
        getPlatformBanksAsOptions: builder.query({
            query: (data) => ({
                url: "",
                method: methods.GET,
                data,
            }),
        }),
    }),
    refetchOnMountOrArgChange: true,
});

export const { useGetPlatformBanksAsOptionsQuery } = bankApi;
export default bankApi;
