import { splitTypes } from "@/utils/constants";
import { sumBy } from "lodash";
import { createContext, useContext, useMemo } from "react";
import ty from "typy";

// Contexts and Hooks
export const ProductIntegrationContext = createContext();
export const useProductIntegration = () => useContext(ProductIntegrationContext);

export const MerchantConfigurationContext = createContext();
export const useMerchantConfiguration = () => useContext(MerchantConfigurationContext);

export const useIsPlatform = (targetProcessor) => {
    const {
        values: { platform },
    } = useProductIntegration();
    return useMemo(() => platform === targetProcessor, [platform, targetProcessor]);
};

//  Util Functions
const getParametersArr = (parameters) => {
    if (!parameters || !ty(parameters).isObject) return null;
    return Object.entries(parameters).map(([name, value]) => ({ name, value }));
};

export const getTotalPercentage = (beneficiaries = [], splitType) => {
    if (splitType !== splitTypes.PERCENTAGE) return 0;
    return sumBy(beneficiaries, ({ amount }) => Number(amount));
};

export const splitMenu = [
    {
        value: splitTypes.FIXED,
        label: "Fixed",
    },
    {
        value: splitTypes.RELATIVE,
        label: "Relative",
    },
    {
        value: splitTypes.PERCENTAGE,
        label: "Percentage",
    },
];

export const transformProductIntegration = (formBody) => {
    const { beneficiaries, parameters, splitType, charge, isSplit, ...restFormBody } = formBody;
    let requestBody = {
        ...restFormBody,
        charge: Number(charge) || 0,
    };

    if (parameters) {
        requestBody = { ...requestBody, parameters: getParametersArr(parameters) };
    }

    if (isSplit) {
        requestBody = {
            ...requestBody,
            splitDetails: {
                type: splitType,
                beneficiaries: beneficiaries?.map(({ amount, bank, ...restBeneficiary }) => ({
                    ...restBeneficiary,
                    bankCode: bank.code,
                    amount: Number(amount),
                })),
            },
        };
    }
    return requestBody;
};
