import identity from "lodash/identity";
import pickBy from "lodash/pickBy";
import capitalize from "lodash/capitalize";
import moment from "moment";
import format from "date-fns/format";
import isValidDate from "date-fns/isValid";
import ty from "typy";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";

import { theme } from "@/style";
import { CUSTOMER_ID_PREFIX, splitTypes, viewModes } from "./constants";
import { statuses, NOTFOUND_ERROR_MESSAGE, UNKNOWN_ERROR_MESSAGE } from "./constants";
import { Notify } from "@flexisaf/flexibull2/build/notify";

/**  Storage **/
export const asyncLocalStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    },
    clear: function () {
        return Promise.resolve().then(function () {
            return localStorage.clear();
        });
    },
};

export const asyncSessionStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            sessionStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return sessionStorage.getItem(key);
        });
    },
    clear: function () {
        return Promise.resolve().then(function () {
            return sessionStorage.clear();
        });
    },
};

/**  Hashing **/
export const hash = (value) => {
    if (value) {
        let h = 0;
        let l = value.length;
        let i = 0;
        if (l > 0) while (i < l) h = ((h << 5) - h + value.charCodeAt(i++)) | 0;
        return Math.abs(h);
    } else {
        return value;
    }
};

/**  Date/Time Utilities **/
export const timeConverter = (value) => {
    // converts seconds to MM:SS format
    if (value) {
        let hr = value && Math.floor((value / 3600) << 0);
        let min = value && Math.floor((value / 60) << 0);
        let sec = value && Math.floor(value % 60);
        let duration = `${min}:${sec}`;
        if (hr > 0) {
            min = min % (hr * 60);
            duration = `${hr}:${min}:${sec}`;
        }
        return duration;
    } else return value;
};
export const dateFormatter = (dateStr, strFormat) => {
    const dateObj = new Date(dateStr);
    if (!isValidDate(dateObj)) return;
    return format(dateObj, strFormat);
};

export const getYear = (time) => {
    let year = moment().format("YYYY");
    if (time) {
        year = moment(time, "DD/MM/YYYY HH:mm:ss").format("YYYY");
    }
    return year;
};

export const formatDate = (date) => moment(date, "YYYY-MM-DD").format("DD MMM YYYY");

export const formatDateRangeAsStr = (dateRange = {}) => {
    if (!ty(dateRange.startDate).isDate || !ty(dateRange.endDate).isDate) {
        throw new Error("Invalid date range supplied", dateRange);
    }

    const d1 = dateRange.startDate?.getTime();
    const d2 = dateRange.endDate?.getTime();

    return `${d2.toString()}-${d1.toString()}`;
};

export const formatStrDateAsRange = (dateStr = "") => {
    const [d2, d1] = dateStr.split("-").map((dRange) => Number(dRange));
    if (Number.isNaN(d2) || Number.isNaN(d1)) {
        return null;
    }
    return { startDate: new Date(d1), endDate: new Date(d2) };
};

export const sortTime = (arr, key) => {
    let data = arr.sort(
        (a, b) =>
            (a ? moment(a[key], "DD/MM/YYYY HH:mm:ss") : "") -
            (b ? moment(b[key], "DD/MM/YYYY HH:mm:ss") : "")
    );
    return data;
};

export const timeElapsed = (thenProps, nowProps, endText) => {
    let then = thenProps ? moment(thenProps, "DD/MM/YYYY HH:mm:ss") : moment().utc();
    let now = nowProps ? moment(nowProps, "DD/MM/YYYY HH:mm:ss") : moment().utc();
    let thenString = then.format("DD/MM/YYYY HH:mm:ss");
    let nowString = now.format("DD/MM/YYYY HH:mm:ss");

    now = moment(nowString, "DD/MM/YYYY HH:mm:ss");
    then = moment(thenString, "DD/MM/YYYY HH:mm:ss");

    thenString = then.format("DD/MM/YYYY HH:mm:ss");
    nowString = now.format("DD/MM/YYYY HH:mm:ss");

    const elapsedTime = moment(now - then).utc();
    const months = elapsedTime.format("M") - 1;
    const days = elapsedTime.format("D") - 1;
    const hours = elapsedTime.format("HH");
    const minutes = elapsedTime.format("mm");
    const seconds = elapsedTime.format("ss");

    const statement = (value, type) => {
        return `${Number(value)} ${type}${value > 1 ? "s" : " "} ${endText ? endText : "ago"}`;
    };

    if (then > now) {
        return statement(0, "second");
    }

    if (months > 0) {
        return statement(months, "month");
    }

    if (days > 0) {
        return statement(days, "day");
    }

    if (hours > 0) {
        return statement(hours, "hour");
    }

    if (minutes > 0) {
        return statement(minutes, "min");
    }

    if (seconds > 0) {
        return statement(seconds, "second");
    }
};

export const calcViewMode = () => {
    let viewWidth = window.innerWidth;
    if (600 > viewWidth) {
        return viewModes.MOBILE;
    } else if (900 > viewWidth) {
        return viewModes.TABLET;
    } else {
        return viewModes.DESKTOP;
    }
};

/** String Processing **/
export const formatCurrency = (amount, opts = {}) => {
    const fullOpts = { locale: "en-NG", currency: "NGN", minimumFractionDigits: 0, ...opts };
    const { locale, ...actualOpts } = fullOpts;
    return new Intl.NumberFormat(locale, {
        style: "currency",
        ...actualOpts,
    }).format(amount);
};

export const generateDefaultCustomerId = (lastId) => {
    const match = lastId.match(new RegExp(`${CUSTOMER_ID_PREFIX}(\\d+)`));
    if (!match) return "";
    const newDigit = +match[1] + 1;
    return `${CUSTOMER_ID_PREFIX}${newDigit}`;
};

export const titleCase = (str = "") => startCase(toLower(str));

export const snakeCaseToCapital = (snakeString = "") =>
    snakeString
        .split("_")
        .map((w) => capitalize(w))
        .join(" ");

/** Number Formatting **/
export const nairaFormatter = (value) => {
    return value.toLocaleString("en-NG", {
        style: "currency",
        currency: "NGN",
        minimumFractionDigits: Number.isInteger(value) ? 0 : 2,
    });
};

export const formatBeneficiaryAmount = (amount, splitType) => {
    if (splitType === splitTypes.PERCENTAGE) {
        return ty(amount).isNumber ? `${amount}%` : "0%";
    }
    if (splitType === splitTypes.RELATIVE && amount === 0) {
        return "BAL";
    }
    return formatCurrency(amount, { minimumFractionDigits: 2 });
};

export const currencyTest = (payload) => {
    const regex = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;

    return regex.test(payload);
};

/** Misc Utils **/
export const truncateText = (text, limit, limitExtension) => {
    let extension = limitExtension ? limitExtension : "...";
    let extensionPlus = limitExtension ? limitExtension.length : 3;
    let extensionLimit = limit ? limit : 17;

    if (text) {
        let value =
            text.length > extensionLimit
                ? `${text.slice(0, extensionLimit + extensionPlus)}${extension}`
                : text;
        return value;
    } else {
        return text;
    }
};

export const removeDuplicate = (arr, key) => {
    let data = [];
    arr &&
        arr.forEach((item) => {
            let index = data.findIndex((list) => list[key] === item[key]);
            if (index === -1) {
                data.push(item);
            } else {
                return null;
            }
        });
    return data;
};

export const searchAllFields = (arr, target) => {
    if (!arr?.length) return [];
    if (!target) return arr;

    const filteredArr = arr.reduce((acc, obj) => {
        for (let key in obj) {
            const value = obj[key];
            if (ty(value).isString && value.toLowerCase().includes(target.toLowerCase())) {
                acc.push(obj);
                return acc;
            }
        }
        return acc;
    }, []);
    return filteredArr;
};
export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export const formatCount = (value) => {
    if (1000 > value) return `${value}`;
    if (1000000 > value) return `${round(value / 1000, 1)}k`;
    if (1000000000 > value) return `${round(value / 1000000, 1)}M`;
    return value;
};

export const filterEmptyString = (item) => {
    return Object.fromEntries(Object.entries(item).filter(([, value]) => value !== ""));
};

export const filterFalsyValues = (item) => pickBy(item, identity);

export const arrayHash = (arr = [], { key }) =>
    arr.reduce((targetObj, item) => {
        targetObj[item[key]] = item;
        return targetObj;
    }, {});

export const noop = () => {};

export const parseError = (error) => {
    if (ty(error).isObject && error?.message) {
        return error.message;
    }
    if (ty(error).isString) return error;

    if (error?.statusCode === 404) {
        return NOTFOUND_ERROR_MESSAGE;
    }

    return UNKNOWN_ERROR_MESSAGE;
};

export const getSchoolAsOptions = (schools) =>
    Array.isArray(schools) ? schools.map(({ id, name }) => ({ label: name, value: id })) : [];

export const getStatusColorForTag = (status) => {
    let fontColor = "black";
    if (status === statuses.PENDING) return { fontColor, color: theme.PrimaryYellow };

    if (status === statuses.SUCCESS) return { fontColor, color: theme.PrimaryGreen };

    if (status === statuses.FAILED) return { fontColor: "white", color: theme.PrimaryRed };
    return null;
};

/** Notifications **/
export const asyncNotification = async (message, props = { position: "top-left" }) => {
    const notif = () => new Promise((resolve) => resolve(Notify(message, props)));
    await notif();
};

export function getStatusColor(status) {
    if (!ty(status).isString) {
        return theme.PrimaryColor;
    }
    let color = "";

    switch (status.toUpperCase()) {
        case statuses.PENDING:
            color = theme.PrimaryYellow;
            break;
        case statuses.SUCCESS:
            color = theme.PrimaryGreen;
            break;
        case statuses.FAILED:
            color = theme.PrimaryRed;
            break;
        default:
            color = theme.PrimaryColor;
    }

    return color;
}

export const getErrorOrFallbackMsg = (error, fallbackMsg = UNKNOWN_ERROR_MESSAGE) =>
    error?.message || fallbackMsg;

export const hasItems = (collection = [], min = 1) =>
    Array.isArray(collection) && collection.length >= min;

export const maybeNA = (val, naText = "N/A") => (val ? val : naText);
/* eslint-disable */
export const isEnv = (targetEnv) => process.env.NODE_ENV === targetEnv;
