import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import pick from "lodash/pick";
import qs from "qs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { DEFAULT_LIMIT } from "@/utils/constants";

const paginationParameters = ["page", "start", "limit", "offset"];
const notFilterParameters = [...paginationParameters, "sortBy"];

const parseQueryString = (queryStr) => qs.parse(queryStr, { ignoreQueryPrefix: true });
const stringifyQuery = (queryObj) => qs.stringify(queryObj, { skipNulls: true });

function useResourceParams(defaultParams) {
    const location = useLocation();
    const navigate = useNavigate();
    const [params, setParams] = useState({});
    const mountRef = useRef(false);

    const queryObj = useMemo(() => parseQueryString(location.search), [location.search]);

    const updateLocationQuery = useCallback(
        (queryObjArg) => {
            const queryStr = stringifyQuery(queryObjArg);
            navigate(`${location.pathname}?${queryStr}`);
        },
        [location.pathname]
    );

    useEffect(() => {
        if (!mountRef.current) {
            if (!isEmpty(queryObj)) {
                setParams(queryObj);
                return;
            }

            if (!isEmpty(defaultParams)) {
                setParams(defaultParams);
                updateLocationQuery(defaultParams);
            }
        }
        mountRef.current = true;
    }, []);

    const setParam = useCallback(
        (name, value) => {
            const newParams = { ...params, [name]: value };
            updateLocationQuery({ ...queryObj, ...newParams });
            setParams(newParams);
        },
        [queryObj, params]
    );

    const clearParam = useCallback(
        (paramName) => {
            updateLocationQuery(omit(queryObj, paramName));
            setParams(omit(params, paramName));
        },
        [queryObj, params]
    );

    const resetParams = useCallback(
        (resetValue = defaultParams) => {
            setParams(resetValue);
            const queryObjWithoutParams = omit(queryObj, ...Object.keys(params));
            const newQueryObj = { ...queryObjWithoutParams, ...resetValue };
            updateLocationQuery(newQueryObj);
        },
        [queryObj, params]
    );

    return {
        params,
        setParam,
        clearParam,
        resetParams,
    };
}

export const defaultPaginationParams = { start: 0, limit: DEFAULT_LIMIT };

const defaultSetPageConfig = { paramName: "start" };

export function usePaginationParams(defaultPagination = defaultPaginationParams) {
    const { params, setParam } = useResourceParams(defaultPagination);

    const pagination = useMemo(() => pick(params, paginationParameters), [params]);

    const currentPage = useMemo(() => {
        const { start, limit } = pagination;
        return Math.ceil((+start + +limit) / +limit) || 1;
    }, [pagination]);

    const setLimit = useCallback((limit) => setParam("limit", limit), [setParam]);

    const setPage = useCallback(
        (selectedPage, { paramName } = defaultSetPageConfig) => {
            const { limit } = pagination;
            const paramValue = limit * (selectedPage - 1);
            setParam(paramName, paramValue);
        },
        [pagination, setParam]
    );

    return {
        pagination: isEmpty(pagination) ? defaultPagination : pagination,
        currentPage,
        setPage,
        setLimit,
    };
}

export function useFilterParams(defaultFilters) {
    const { params, setParam, clearParam } = useResourceParams(defaultFilters);
    const filters = useMemo(() => omit(params, notFilterParameters), [params]);

    const setFilter = useCallback(
        (name, value) => {
            if (!name) {
                throw new Error("No name is given to filter");
            }

            setParam(name, value);
        },
        [setParam]
    );

    return {
        filters,
        setFilter,
        clearFilter: clearParam,
    };
}

export function useSortParams(defaultSort) {
    const { params, setParam } = useResourceParams(defaultSort && { sortBy: defaultSort });

    const setSortBy = useCallback(
        ({ field, sortDirection }) => {
            if (!field || !sortDirection)
                throw new Error("'field' or 'sortDirection' not provided");
            setParam("sortBy", `${field}.${sortDirection}`);
        },
        [setParam]
    );
    const { sortBy } = params;

    return {
        sortBy,
        setSortBy,
    };
}
