import PropTypes from "prop-types";

import { Button } from "@flexisaf/flexibull2/build/button";

import { theme } from "@/style";
import { parseError } from "@/utils/help";

import { ErrorOccuredWrapper } from "./ErrorOccured.styled";

const nonRetryableStatuses = [404, 500];
export default function ErrorOccured(props) {
    const { error, retry } = props;

    return (
        <ErrorOccuredWrapper>
            <div className="message"> {parseError(error)}</div>
            {retry && !nonRetryableStatuses.includes(error.statusCode) && (
                <Button color={theme.PrimaryRed} onClick={retry}>
                    Retry
                </Button>
            )}
        </ErrorOccuredWrapper>
    );
}

ErrorOccured.propTypes = {
    error: PropTypes.any,
    retry: PropTypes.func,
};
