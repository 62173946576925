import PropTypes from "prop-types";

import { Text } from "@flexisaf/flexibull2/build/typo";

import { ErrorPageWrapper } from "./ErrorPage.styled";
import {
    error400Logo,
    error401Logo,
    error403Logo,
    error404Logo,
    error408Logo,
    error500Logo,
    error502Logo,
    error503Logo,
    error504ogo,
    unknownErrorLogo,
} from "@/assets";

export default function ErrorPage(props) {
    const { action, status, customTitle } = props;
    const { logo, text, title } = statusObject[status?.toString() || "520"];
    return (
        <ErrorPageWrapper>
            <div className="illustration-container" style={{ marginLeft: "0.5em" }}>
                <img alt="error-icon" src={logo} />
            </div>
            <Text className="title"> {customTitle || title}</Text>
            <Text className="text">{text}</Text>
            <div>{action && action}</div>
        </ErrorPageWrapper>
    );
}

ErrorPage.propTypes = {
    action: PropTypes.any,
    status: PropTypes.number,
    customTitle: PropTypes.string,
};

const statusObject = {
    ["400"]: {
        logo: error400Logo,
        title: "Bad request",
        text: "Your Browser sent a request that this server could not understand",
    },
    ["401"]: {
        logo: error401Logo,
        title: "Page not found",
        text: "We are sorry the page you requested could not be found",
    },
    ["403"]: {
        logo: error403Logo,
        title: "Forbidden",
        text: "Oops! The page you are trying to access has restricted access",
    },
    ["404"]: {
        logo: error404Logo,
        title: "Page not found",
        text: "We are sorry the page you requested could not be found",
    },
    ["408"]: {
        logo: error408Logo,
        title: "Request Timeout",
        text: "Sorry your request took too long to process, please try again",
    },
    ["500"]: {
        logo: error500Logo,
        title: "Server Error",
        text: "Oops! Something went wrong, sorry for the inconveince, we are working on it. Please contact support if it persists admin@paylog.com",
    },
    ["502"]: {
        logo: error502Logo,
        title: "Bad Gateway",
        text: "Oops! Sorry the server is unreachable at this time, please try again later or  report the issue here admin@paylog.com",
    },
    ["503"]: {
        logo: error503Logo,
        title: "Service temporarily unavailable",
        text: "We are sorry, this service is unavailable at the moment, please check back shortly or  report the issue here admin@paylog.com",
    },
    ["504"]: {
        logo: error504ogo,
        title: "Gateway timeout",
        text: "Oops! Sorry the request took too long to process, please try again later or report the issue here admin@paylog.com",
    },
    ["520"]: {
        logo: unknownErrorLogo,
        title: "An unknown error has occured",
        text: (
            <span>
                Please contact support at{" "}
                <a href="mailto:support@flexisaf.com">support@flexisaf.com</a>
            </span>
        ),
    },
};
