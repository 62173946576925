import { createApi } from "@reduxjs/toolkit/query/react";
import axios from "axios";

import { Notify } from "@flexisaf/flexibull2/build/notify";

import { createBaseQuery } from "@/lib";
import cacher from "@/utils/rtkQueryCacheUtils";
import { handleRequestError } from "@/lib/request";
import { methods, verificationTypes } from "@/utils/constants";
import { authMessages as messages } from "@/utils/mesages";
import config from "@/utils/config";
import { getErrorOrFallbackMsg } from "@/utils/help";

const authBaseUrl = `${config.umsURL}/auth/products/${config.umsProductId}/organisations/${config.umsOrganisation}`;

const authApi = createApi({
    baseQuery: createBaseQuery({ baseURL: authBaseUrl }),
    tagTypes: [...cacher.defaultTags, "AUTH"],
    reducerPath: "authApi",
    endpoints: (builder) => ({
        accountConfirmation: builder.query({
            query: (data) => ({
                url: "/confirm",
                data,
                method: methods.POST,
            }),
        }),
        login: builder.mutation({
            async queryFn(data) {
                try {
                    const url = `${config.apiURL}/auth/login`;
                    const resp = await axios.post(url, data);
                    return { data: transformAuthResponse(resp.data) };
                } catch (ex) {
                    const error = handleRequestError(ex);
                    return { error };
                }
            },
        }),
        oauthTransfer: builder.query({
            query: (data) => ({
                url: "/transfer",
                data,
            }),
        }),
        forgotPassword: builder.mutation({
            query: (data) => ({
                url: "/password/reset-request",
                data,
                method: methods.POST,
            }),
        }),
        resendConfirmation: builder.mutation({
            query: (username) => ({
                url: "/resend-confirm",
                data: { username, verification_type: verificationTypes.LINK },
                method: methods.POST,
            }),
            onQueryStarted(email, { queryFulfilled: qf }) {
                qf.then(() => Notify(messages.getResendConfirmationMailMessage(email))).catch(
                    ({ error }) => Notify(getErrorOrFallbackMsg(error), { status: "error" })
                );
            },
        }),
        resetPassword: builder.mutation({
            query: (data) => ({
                url: "/password/reset",
                data,
                method: methods.POST,
            }),
        }),
    }),
});

function transformAuthResponse(data) {
    const { otherName, authToken, refreshToken, ...restUser } = data;
    const otherNameOrSpace = otherName ? `${otherName} ` : " ";
    return {
        authToken,
        refreshToken,
        user: {
            ...restUser,
            fullName: `${data.firstName} ${otherNameOrSpace}${data.lastName}`,
            rawPrivileges: getRawPrivilegesFromRoles(restUser.roles),
        },
    };
}

function getRawPrivilegesFromRoles(userRoles) {
    const allPrivileges = userRoles.reduce((acc, roleItem) => {
        acc = acc.concat(roleItem.role.privileges);
        return acc;
    }, []);

    return allPrivileges.map((privilege) => privilege?.name);
}

export const {
    useLoginMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useResendConfirmationMutation,
    useAccountConfirmationQuery,
} = authApi;

export default authApi;
