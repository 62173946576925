import { theme } from "@/style";
import { breakpoints } from "@/utils/constants";
import styled, { css } from "styled-components";

export const TabsWrapper = styled.section`
    display: flex;
    width: 100%;
    margin-bottom: 2em;
    @media ${breakpoints.MOBILE} {
        flex-flow: column nowrap;
    }
    @media ${breakpoints.TABLET} {
        flex-flow: row nowrap;
    }
`;

export const TabWrapper = styled.li`
    border-radius: 8px;
    flex: 0 0 auto;
    ${(p) =>
        p.isActive &&
        css`
            background: ${theme.PrimaryColor};

            & button {
                color: white;
            }
        `}

    & button {
        cursor: pointer;
        background: none;
        height: 100%;
        border: 0px;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0.3em 0.6em;
        transition: 0.2s ease-in-out;
        & .text {
            width: 60%;
            text-overflow: ellipsis;
            margin-right: 1.2em;
            text-align: left;
        }
        & svg {
            stroke: white;
            width: 1.6em;
            height: 1.6em;
        }
    }

    @media ${breakpoints.TABLET} {
        border-radius: 8px 0 0 8px;
        & button {
            padding: 1.6em 1.2em;
        }
    }
`;

export const TabList = styled.ul.attrs({ className: "tab-list" })`
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    width: 14em;
    -webkit-overflow-scrolling: touch;

    @media ${breakpoints.MOBILE} {
        overflow-x: scroll;
        flex-direction: row;
        margin-bottom: 1em;
    }

    @media ${breakpoints.TABLET} {
        flex-direction: column;
    }
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const PanelWrapper = styled.section.attrs({ className: "panel-wrapper" })`
    border: 2px solid ${theme.SecondaryGrey};
    flex: 1;
`;
