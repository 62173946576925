import { Text } from "@flexisaf/flexibull2/build/typo";
import { SideBarToogle } from "@flexisaf/flexibull2/build/navigation";
import { Box } from "@flexisaf/flexibull2/build/layout";

import { paylogLogo } from "@/assets";
import { LogoContainer } from "./layoutStyles";

export default function Brand() {
    return (
        <Box display="flex" style={{ alignItems: "center" }}>
            <LogoContainer>
                <img src={paylogLogo} alt="logo" />
            </LogoContainer>
            <Text size="1.5em" bold>
                PAYLOG
                <SideBarToogle />
            </Text>
        </Box>
    );
}
