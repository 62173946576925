import { noop } from "lodash";
import { DevTool } from "@hookform/devtools";
import PropTypes from "prop-types";
import { FormProvider } from "react-hook-form";
import { useKey } from "react-use";

import { isEnv } from "@/utils/help";
import { PRODUCTION_ENV } from "@/utils/constants";

import Input from "./Input";
import Checkbox from "./Checkbox";
import Select from "./Select";
import Switch from "./Switch";
import TextArea from "./TextArea";
import CardSelector from "./CardSelector";

import PhoneInput from "./phone-input/PhoneInput";
function HookForm(props) {
    const { form, onSubmit = noop, children } = props;
    useKey("Enter", () => onSubmit());

    return (
        <FormProvider {...form}>
            <form onSubmit={onSubmit ? form.handleSubmit(onSubmit) : noop}>
                {children}
                {!isEnv(PRODUCTION_ENV) && <DevTool control={form.control} />}
            </form>
        </FormProvider>
    );
}

HookForm.propTypes = {
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    children: PropTypes.node.isRequired,
};

export default Object.assign(HookForm, {
    Input,
    Checkbox,
    Select,
    Switch,
    TextArea,
    CardSelector,
    PhoneInput,
});
