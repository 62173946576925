import React from "react";
import PropTypes from "prop-types";
import { Wizard } from "@flexisaf/flexibull2/build/wizard";

import StepButtons from "./StepButtons";
import { reducer, actions } from "./stepperUtils";

const { createContext, useContext, useReducer, useCallback } = React;

const StepperContext = createContext();
export const useStepper = () => useContext(StepperContext);

const initialState = {
    totalSteps: 0,
    view: 0,
};

function Stepper(props) {
    const { children, title, width = "100%", ...restStepperProps } = props;

    const [store, dispatch] = useReducer(reducer, initialState, (initial) => {
        return {
            totalSteps: Array.isArray(children) ? children.length : initial.totalSteps,
            view: restStepperProps.defaultView || initial.view,
        };
    });

    const handleNext = useCallback(() => dispatch(actions.next()), []);
    const handlePrev = useCallback(() => dispatch(actions.prev()), []);
    const setView = useCallback((view) => dispatch(actions.set(view)), []);

    const steps = React.Children.map(children, (child) => child);

    return (
        <StepperContext.Provider value={{ ...store, handleNext, handlePrev, setView }}>
            <Wizard width={width} disableLabels autoProceed={false} title={title} view={store.view}>
                {steps}
            </Wizard>
        </StepperContext.Provider>
    );
}

Stepper.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    defaultView: PropTypes.number,
    onSubmit: PropTypes.func,
    width: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
};

export default Object.assign(Stepper, {
    StepButtons,
});
