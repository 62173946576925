const actionTypes = {
    NEXT: "NEXT",
    PREVIOUS: "PREVIOUS",
    SET_VIEW: "SET_VIEW",
};

export const actions = {
    next: () => ({ type: actionTypes.NEXT }),
    prev: () => ({ type: actionTypes.PREVIOUS }),
    set: (payload) => ({ type: actionTypes.SET_VIEW, payload }),
};

export const reducer = (state, action) => {
    const { view, totalSteps } = state;
    const lastView = totalSteps - 1;
    switch (action.type) {
        case actionTypes.NEXT:
            return view < lastView ? { ...state, view: view + 1 } : state;
        case actionTypes.PREVIOUS:
            return view > 0 ? { ...state, view: view - 1 } : state;
        case action.SET_VIEW:
            return view > 0 && view < lastView ? { ...state, view: action.payload } : state;
        default:
            throw new Error("Unknown action");
    }
};
