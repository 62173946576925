import { Link, Outlet, useNavigate } from "react-router-dom";

import { Button } from "@flexisaf/flexibull2/build/button";
import { FlexiPagination } from "@flexisaf/flexibull2/build/table";
import { Box, Grid, Spacer } from "@flexisaf/flexibull2/build/layout";
import { Tag } from "@flexisaf/flexibull2/build/tags";

import { SearchBar, Loadable, Table, Select } from "@/components";
import {
    IconDelete,
    IconDoneCircle,
    IconEdit,
    IconFailCircle,
    IconToSend,
} from "@/components/icons";

import { pageOptions } from "@/utils/uiOptions";
import { theme } from "@/style";
import { maybeNA } from "@/utils/help";
import { roleColors } from "@/utils/constants";

import { usersRoutes } from "../../allRoutes";
import { useUsers } from "./useUsers";

const iconSuccess = <IconDoneCircle width={24} height={24} stroke={theme.PrimaryGreen} />;
const iconFail = <IconFailCircle width={24} height={24} stroke={theme.PrimaryRed} />;

const columns = [
    {
        key: "id",
        title: "Profile Name",
        render: ({ first_name, last_name }) => ` ${first_name} ${last_name}`,
    },
    {
        title: "Email",
        dataIndex: "email",
    },

    {
        title: "Phone Number",
        render: ({ phone }) => maybeNA(phone, "---"),
    },
    {
        title: "Role",
        render: ({ role }) => (
            <Tag
                key={role.id}
                color={roleColors[role.name]}
                style={{ height: "40px", border: "none" }}
            >
                <b> {role.description} </b>
            </Tag>
        ),
    },
    {
        title: "Verified",
        render: ({ verified }) => <i>{verified ? iconSuccess : iconFail}</i>,
    },
];

export default function Users() {
    const navigate = useNavigate();
    const { errors, usersData, rolesData, filters, currentPage, actions, loading } = useUsers();

    return (
        <Box>
            <Grid default="1fr 1fr">
                <Box display="flex" style={{ alignItems: "center", gap: "2em" }}>
                    <SearchBar
                        wide
                        placeholder="Search"
                        value={filters.search}
                        onChange={(value) => actions.setFilter("search", value)}
                        debounceInterval={1500}
                        onClear={() => actions.clearFilter("search")}
                    />
                    <Select
                        value={filters.role}
                        onChange={(roleId) => actions.setFilter("role", roleId)}
                        options={rolesData?.entities}
                        getOptionLabel={(role) => role.description}
                        getOptionValue={(role) => role.id}
                    />
                </Box>
                <Box display="flex" style={{ alignItems: "center", justifyContent: "flex-end" }}>
                    <Link to={usersRoutes.createUser}>
                        <Button width={148}> Add user</Button>
                    </Link>
                </Box>
            </Grid>

            <Spacer space={12} />

            <Loadable isLoading={loading.isLoadingUsers} height="70vh" error={errors.usersError}>
                <Table
                    columns={columns}
                    data={usersData?.entities}
                    isUpdating={loading.isUpdatingUsers}
                    actions={[
                        {
                            icon: <IconEdit />,
                            label: "Edit",
                            title: "Edit",
                            fn: ({ id }) => {
                                navigate(usersRoutes.getEditUser(id));
                            },
                        },

                        {
                            icon: <IconToSend />,
                            label: "Delete",
                            title: "Resend Confirmation",
                            hidden: ({ verified }) => verified,
                            fn: (user) => actions.resendConfirmation(user),
                        },
                        {
                            icon: <IconDelete />,
                            label: "Delete",
                            title: "Delete",
                            fn: (user) => actions.deleteUser(user),
                        },
                    ]}
                />

                {usersData?.entities && (
                    <FlexiPagination
                        pageCounts={pageOptions}
                        itemsDisplayed
                        total={usersData?.meta.total}
                        pageSize={usersData?.meta.limit}
                        changePageSize={({ value }) => actions.setLimit(value)}
                        onChange={(val) => actions.setPage(val, { paramName: "offset" })}
                        current={currentPage}
                    />
                )}
            </Loadable>
            <Outlet />
        </Box>
    );
}
