import styled, { css } from "styled-components";

export const StyledButton = styled.div.attrs({ className: "flexi-button-wrapper" })`
    ${(p) =>
        p.disabled &&
        css`
            & svg {
                stroke: gray;
            }
        `}
`;
