import { CardSelector as FlexiCardSelector } from "@flexisaf/flexibull2/build/cardselectors";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";

const getCheckSelectorNewValue = (oldValue, selectedValue, isChecked) => {
    if (!oldValue) {
        return [selectedValue];
    }

    if (oldValue) {
        if (Array.isArray(oldValue)) {
            if (!isChecked) {
                const isAlreadyIncluded = oldValue.some((val) => val === selectedValue);
                if (isAlreadyIncluded) {
                    return [...oldValue.filter((val) => val !== selectedValue)];
                }
                return;
            }
            return [...oldValue, selectedValue];
        }

        return [oldValue, selectedValue];
    }
};

export default function CardSelector(props) {
    const { name, label, type = "radio", rules = {}, ...restProps } = props;
    const { field } = useController({ name, rules });

    const handleChange = (event) => {
        const { value, checked } = event.target;

        if (type === "checkbox") {
            const newValue = getCheckSelectorNewValue(field.value, value, checked);
            field.onChange(newValue);
            return;
        }

        field.onChange(value);
    };

    return (
        <FlexiCardSelector
            name={name}
            label={label}
            type={type}
            onChange={handleChange}
            {...restProps}
        />
    );
}

CardSelector.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    type: PropTypes.string,
    rules: PropTypes.object,
};
