import { theme } from "@/style";
import styled from "styled-components";

export const ErrorOccuredWrapper = styled.div`
    text-align: center;
    & .message {
        margin-bottom: 1em;
        color: ${theme.PrimaryRed};
    }
`;
