import { useState, useEffect } from "react";

import { calcViewMode } from "@/utils/help";

export const useViewMode = () => {
    const [viewMode, setViewMode] = useState(() => {
        return calcViewMode();
    });

    useEffect(() => {
        const handleViewChange = () => {
            setViewMode(() => {
                return calcViewMode();
            });
        };

        window.addEventListener("resize", handleViewChange);

        return () => window.removeEventListener("resize", handleViewChange);
    }, []);

    return viewMode;
};
