import { yupResolver } from "@hookform/resolvers/yup";
import omit from "lodash/omit";
import { useState, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { Box } from "@flexisaf/flexibull2/build/layout";
import { Switch } from "@flexisaf/flexibull2/build/switch";
import { Text } from "@flexisaf/flexibull2/build/typo";

import { HookForm as Form, Stepper, Grid } from "@/components";
import { useStepper } from "@/components/stepper/Stepper";
import { theme } from "@/style";
import { platforms, splitTypes } from "@/utils/constants";

import Beneficiary, {
    AddBeneficiaryButton,
    newBeneficiary,
    BENEFICIARY_FIELD_NAME,
} from "../Beneficiary";
import { ContentBaseWrapper, TransactionChargeWrapper } from "./productIntegrationStyles";
import {
    useProductIntegration,
    useIsPlatform,
    getTotalPercentage,
} from "../productIntegrationUtils";

import {
    onSubmitValidation,
    transactionChargeValidation,
    transactionChargeErrors,
} from "../productIntegrationValidation";

const firstBeneficiaryAmountFieldName = `${BENEFICIARY_FIELD_NAME}.0.amount`;

export default function TransactionCharge() {
    const { values, addToValues, setValues } = useProductIntegration();
    const { handleNext } = useStepper();
    const [error, setError] = useState(null);

    const form = useForm({
        resolver: yupResolver(transactionChargeValidation),
    });
    const { isSplit, splitType, beneficiaries: allBeneficiaries } = form.watch();

    const beneficiaries = useFieldArray({ control: form.control, name: BENEFICIARY_FIELD_NAME });
    const isInterswitch = useIsPlatform(platforms.INTERSWITCH);
    const isDreamlabs = useIsPlatform(platforms.DREAMLABS);
    const isUnifiedPayments = useIsPlatform(platforms.UNIFIED_PAYMENT);

    const splitTypeError = form.formState.errors?.splitType?.message;

    const totalPercentage = getTotalPercentage(allBeneficiaries, splitType);

    useEffect(() => {
        // If user has added any beneficiary,
        // and the splitType is splitTypes.RELATIVE
        // the first amount field is set to "BALANCE",
        // otherwise it is changed to ''
        if (allBeneficiaries && allBeneficiaries[0]) {
            if (splitType === splitTypes.RELATIVE) {
                form.setValue(firstBeneficiaryAmountFieldName, "BALANCE");
            } else {
                const firstBeneficiaryAmount = allBeneficiaries[0].amount;
                if (firstBeneficiaryAmount === "BALANCE") {
                    form.setValue(firstBeneficiaryAmountFieldName, "");
                }
            }
        }
    }, [splitType, allBeneficiaries]);

    useEffect(() => {
        // If platform changes, we are resetting the form
        // and removing the saved form values
        form.reset();
        setValues((prevValues) => omit(prevValues, "splitType", "beneficiaries", "charge"));

        // Split is needed for Interswitch
        if (values.platform === platforms.INTERSWITCH) {
            form.setValue("isSplit", true);
            return;
        }
        // Split is not applicable to Dreamlabs and Unified Payments
        if (
            values.platform === platforms.DREAMLABS ||
            values.platform === platforms.UNIFIED_PAYMENT
        ) {
            form.setValue("isSplit", false);
        }
    }, [values.platform]);

    const handleSplitSwitchChange = (event) => {
        setError(null);
        const { checked } = event.target;
        form.setValue("isSplit", checked);

        if (!checked) {
            beneficiaries.remove();
            form.setValue("splitType", null);
        }
    };

    const handleAddBeneficiary = () => {
        if (error?.type === transactionChargeErrors.noBeneficiaries.type) {
            setError(null);
        }
        beneficiaries.append(newBeneficiary);
    };

    const onSubmit = () => {
        setError(null);

        // Issue: We are not using the formBody returned by `form.handleSubmit`
        // because our `boolean` gets cast to a string in the `formBody` by
        // react-hook-form internally
        const values = form.getValues();

        const error = onSubmitValidation(values);
        if (error) {
            setError(error);
            return;
        }

        addToValues(form.getValues());
        handleNext();
    };

    const isSplitSwitchDisabled = isInterswitch || isDreamlabs || isUnifiedPayments;
    const addButtonIsDisabled = totalPercentage >= 100;
    return (
        <ContentBaseWrapper>
            <div className="content">
                <Form form={form}>
                    <TransactionChargeWrapper>
                        <Grid default="1fr" gap="1em">
                            <Box>
                                <Form.Input
                                    placeholder="N0.00"
                                    type="currency"
                                    defaultValue={0}
                                    label="Transaction Charge"
                                    name="charge"
                                />
                                <Box margin="0.5em 0">
                                    <Text size=".9em">
                                        If the transaction charge have been configured before, you
                                        can leave this field empty.
                                    </Text>
                                </Box>
                            </Box>

                            <Switch
                                label="Do you want to configure split"
                                defaultChecked={isInterswitch}
                                checked={isSplit}
                                disabled={isSplitSwitchDisabled}
                                onChange={handleSplitSwitchChange}
                            />

                            {isSplit && (
                                <>
                                    <Grid
                                        width="80%"
                                        tablet="1fr / repeat(3, minmax(0, 1fr))"
                                        gap="1em"
                                    >
                                        <Form.CardSelector
                                            name="splitType"
                                            value={splitTypes.PERCENTAGE}
                                            label="Percentage"
                                            view="label"
                                        />
                                        <Form.CardSelector
                                            name="splitType"
                                            value={splitTypes.FIXED}
                                            label="Fixed"
                                            view="label"
                                        />
                                        <Form.CardSelector
                                            name="splitType"
                                            value={splitTypes.RELATIVE}
                                            label="Relative"
                                            view="label"
                                        />
                                    </Grid>
                                    {splitTypeError && (
                                        <Box margin="1em">
                                            <Text color={theme.PrimaryRed}> {splitTypeError}</Text>
                                        </Box>
                                    )}

                                    <Box>
                                        {beneficiaries.fields.map((beneficiary, index) => (
                                            <Beneficiary
                                                key={beneficiary.id}
                                                index={index}
                                                fieldsIndex={`${BENEFICIARY_FIELD_NAME}.${index}`}
                                                platform={values.platform}
                                                onRemove={() => beneficiaries.remove(index)}
                                                splitType={splitType}
                                            />
                                        ))}
                                    </Box>

                                    {error && (
                                        <Box style={{ marginBottom: "1em" }}>
                                            <Text color={theme.PrimaryRed}>{error.message}</Text>{" "}
                                        </Box>
                                    )}

                                    <Box margin="0 0 2em">
                                        <AddBeneficiaryButton
                                            isDisabled={addButtonIsDisabled}
                                            onClick={handleAddBeneficiary}
                                        />
                                    </Box>
                                </>
                            )}
                        </Grid>
                    </TransactionChargeWrapper>
                </Form>
            </div>
            <Stepper.StepButtons onNext={form.handleSubmit(onSubmit)} />
        </ContentBaseWrapper>
    );
}
