import PropTypes from "prop-types";
import { useTabsContext } from "./Tabs";
import { PanelWrapper } from "./tabsStyles";

export default function Panel(props) {
    const { selectedTab } = useTabsContext();
    const { id, index, title, children } = props;

    return selectedTab !== index ? null : (
        <PanelWrapper
            id={id}
            role="tabpanel"
            aria-labelledby={title}
            tabIndex={selectedTab == index ? 0 : -1}
        >
            {children}
        </PanelWrapper>
    );
}

Panel.propTypes = {
    id: PropTypes.string,
    index: PropTypes.number,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};
