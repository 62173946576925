import { createApi } from "@reduxjs/toolkit/query/react";

import config from "@/utils/config";
import { createBaseQuery } from "@/lib";
import { methods } from "@/utils/constants";
import cacher from "@/utils/rtkQueryCacheUtils";
import { Notify } from "@flexisaf/flexibull2/build/notify";

export const tagType = "CUSTOMERS";

const customerApi = createApi({
    baseQuery: createBaseQuery({ baseURL: `${config.apiURL}/schools` }),
    tagTypes: [tagType],
    reducerPath: "customerApi",
    endpoints: (builder) => ({
        getCustomers: builder.query({
            query: (data) => ({ url: "", method: methods.GET, data }),
            providesTags: [tagType],
        }),
        getCustomer: builder.query({
            query: (id) => ({ url: `/${id}`, method: methods.GET }),
            providesTags: cacher.cacheById(tagType),
        }),
        addCustomer: builder.mutation({
            query: (data) => ({
                url: "",
                method: methods.POST,
                data,
            }),
            invalidatesTags: [tagType],
            onQueryStarted(_, { queryFulfilled: qf }) {
                qf.then(() => Notify("Customer created successfully")).catch((ex) =>
                    Notify(ex.error?.message || "Error creating customer", { status: "error" })
                );
            },
        }),
        editCustomer: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/${id}`,
                method: methods.PUT,
                data,
            }),
            invalidatesTags: [tagType],
            onQueryStarted(_, { queryFulfilled: qf }) {
                qf.then(() => Notify("Customer info updated successfully")).catch((ex) =>
                    Notify(ex.error?.message || "Error updating customer", { status: "error" })
                );
            },
        }),
        deleteCustomer: builder.mutation({
            query: (id) => ({
                url: `/${id}`,
                method: methods.DELETE,
            }),
            invalidatesTags: [tagType],
            onQueryStarted(_, { queryFulfilled: qf }) {
                qf.then(() => Notify("Customer info updated successfully")).catch((ex) => {
                    Notify(ex.error?.message || "Error Deleting CUstomer", { status: "error" });
                });
            },
        }),
    }),
});

export const {
    useGetCustomersQuery,
    useGetCustomerQuery,
    useAddCustomerMutation,
    useEditCustomerMutation,
    useDeleteCustomerMutation,
} = customerApi;

export default customerApi;
