import { Text } from "@flexisaf/flexibull2";
import styled from "styled-components";

export const Heading = styled(Text)`
    font-size: 2em;
`;

export const Description = styled(Text)`
    color: gray;
`;

export const DateText = styled(Text)`
    font-size: 1em;
    margin-bottom: 5px;
    line-height: 6.34px;
`;
export const TimeText = styled(Text)`
    font-size: 0.62em;
    line-height: 10px;
`;
