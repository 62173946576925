import { methods } from "@/utils/constants";
import request from "./request";

export default function createBaseQuery({ baseURL }) {
    if (!baseURL) throw new Error("'baseURL' is required");
    return async (options) => {
        const { url, method = methods.GET, data } = options;
        try {
            const response = await request({ url: baseURL + url, method, data });
            return response;
        } catch (requestError) {
            return requestError;
        }
    };
}
