import { theme } from "@/style";
import { Box, Spacer } from "@flexisaf/flexibull2/build/layout";
import { Text } from "@flexisaf/flexibull2/build/typo";

export default function NotReady() {
    return (
        <Box pad="2em" style={{ background: "white" }}>
            <Text size="2em" color={theme.PrimaryColor} block>
                Feature in Pipeline
            </Text>
            <Spacer space={8} />

            <Text> Feature is not ready for release yet.</Text>
        </Box>
    );
}
