import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { Loader } from "@flexisaf/flexibull2";
import { Box } from "@flexisaf/flexibull2/build/layout";

import App from "@/App";
import { AppLayout, NetworkIndicator, PrivilegeGuard } from "@/components";
import {
    authRoutes,
    appRoute,
    reportsRoute,
    transactionLogsRoutes,
    productIntegrationRoutes,
    dashboardRoutes,
    baseRoute,
    settingsRoute,
    usersRoutes,
    customerRoutes,
} from "./allRoutes";

import CreateProductIntegration from "./product-integrations/create-product-integration/ProductIntegration";
import ProtectedRoute from "@/components/protected-route/ProtectedRoute";
import Users from "@/routes/users/users/Users";
import { appPrivileges } from "@/utils/appPrivileges";

// Lazy Loaded Components
const Welcome = lazy(() => import("./misc/welcome/Welcome"));
const ErrorPage = lazy(() => import("../components/error-page/ErrorPage"));
const Login = lazy(() => import("./auth/login/Login"));
const AccountConfirmation = lazy(() => import("./auth/account-confirmation/AccountConfirmation"));
const ForgotPassword = lazy(() => import("./auth/forgot-password/ForgotPassword"));
const PasswordReset = lazy(() => import("./auth/password-reset/PasswordReset"));

const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const TransactionLogs = lazy(() => import("./transaction-logs/TransactionLogs"));

const Customers = lazy(() => import("./customers/Customers"));
const AddCustomer = lazy(() => import("./customers/AddCustomer"));
const EditCustomer = lazy(() => import("./customers/EditCustomer"));

const ProductIntegrations = lazy(() => import("./product-integrations/ProductIntegrations"));
const ProductIntegration = lazy(() => import("./product-integrations/ProductIntegration"));
const EditProductIntegration = lazy(() =>
    import("./product-integrations/edit-product-integration/EditProductIntegration").then(
        (mod) => ({
            default: mod.EditProductIntegration,
        })
    )
);

const CreateUser = lazy(() => import("./users/CreateUser"));
const EditUser = lazy(() => import("./users/EditUser"));

const Reports = lazy(() => import("./reports/Reports"));
const Settings = lazy(() => import("./settings/Settings"));
const Checkout = lazy(() => import("./checkout/Checkout"));
const DreamLabsCheckout = lazy(() => import("./checkout/DreamLabsCheckout"));
const Receipt = lazy(() => import("./checkout/Receipt"));

const loadingIndicatorScreen = (
    <Box
        display="flex"
        style={{ justifyContent: "center", alignItems: "center", width: "100vw", height: "100vh" }}
    >
        <Loader speed="fast" />
    </Box>
);

export default function AppRouter() {
    return (
        <BrowserRouter>
            <NetworkIndicator />
            <Suspense fallback={loadingIndicatorScreen}>
                <Routes>
                    <Route path={baseRoute} element={<App />}>
                        <Route element={<Welcome />} />
                        <Route index element={<Navigate to={authRoutes.login} />} />
                        <Route path={authRoutes.login} element={<Login />} />
                        <Route
                            path={authRoutes.accountConfirmation}
                            element={<AccountConfirmation />}
                        />
                        <Route path={authRoutes.passwordReset} element={<PasswordReset />} />
                        <Route
                            path={appRoute}
                            element={
                                <ProtectedRoute>
                                    <AppLayout />
                                </ProtectedRoute>
                            }
                        >
                            <Route
                                path={dashboardRoutes.index}
                                element={
                                    <PrivilegeGuard privileges={appPrivileges.readTransactions}>
                                        <Dashboard />
                                    </PrivilegeGuard>
                                }
                            />
                            <Route
                                path={transactionLogsRoutes.index}
                                element={
                                    <PrivilegeGuard privileges={appPrivileges.readTransactions}>
                                        <TransactionLogs />
                                    </PrivilegeGuard>
                                }
                            />
                            <Route
                                path={productIntegrationRoutes.index}
                                element={
                                    <PrivilegeGuard privileges={appPrivileges.readIntegrations}>
                                        <Outlet />
                                    </PrivilegeGuard>
                                }
                            >
                                <Route index element={<ProductIntegrations />} />
                                <Route
                                    path={productIntegrationRoutes.createProductIntegration}
                                    element={
                                        <PrivilegeGuard
                                            privileges={appPrivileges.createIntegrations}
                                        >
                                            <CreateProductIntegration />
                                        </PrivilegeGuard>
                                    }
                                />
                                <Route
                                    path={productIntegrationRoutes.getEditProductIntegration()}
                                    element={
                                        <PrivilegeGuard
                                            privileges={appPrivileges.updateIntegrations}
                                        >
                                            <EditProductIntegration />
                                        </PrivilegeGuard>
                                    }
                                />

                                <Route
                                    path={productIntegrationRoutes.getProductIntegration()}
                                    element={<ProductIntegration />}
                                />
                            </Route>
                            <Route path={customerRoutes.index} element={<Customers />}>
                                <Route
                                    path={customerRoutes.addCustomer}
                                    element={<AddCustomer />}
                                />
                                <Route
                                    path={customerRoutes.getEditCustomer()}
                                    element={<EditCustomer />}
                                />
                            </Route>
                            <Route path={reportsRoute} element={<Reports />} />
                            <Route
                                path={usersRoutes.index}
                                element={
                                    <PrivilegeGuard privileges={appPrivileges.readUsers}>
                                        <Users />
                                    </PrivilegeGuard>
                                }
                            >
                                <Route path={usersRoutes.createUser} element={<CreateUser />} />
                                <Route path={usersRoutes.getEditUser()} element={<EditUser />} />
                            </Route>
                            <Route path={settingsRoute} element={<Settings />} />
                            <Route path="*" element={<Navigate to={dashboardRoutes.index} />} />
                        </Route>

                        <Route path={authRoutes.forgotPassword} element={<ForgotPassword />} />
                        <Route path="/checkout/:processor/:transactionRef" element={<Checkout />} />
                        <Route
                            path="/checkout/dreamlabs/:transactionRef"
                            element={<DreamLabsCheckout />}
                        />
                        <Route path="/receipt/:transactionRef" element={<Receipt />} />
                        <Route path="*" element={<ErrorPage status={404} />} />
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}
