import { useState } from "react";
import { useBeforeUnload } from "react-use";

import Stepper from "@/components/stepper/Stepper";

import ProductDetails from "./ProductDetails";
import CustomerDetails from "./CustomerDetails";
import MerchantConfiguration from "./MerchantConfiguration";
import TransactionCharge from "./TransactionCharge";
import IntegrationSummary from "./IntegrationSummary";
import { ProductIntegrationContext } from "../productIntegrationUtils";
import { ProductIntegrationWrapper } from "./productIntegrationStyles";

export default function ProductIntegration() {
    const [values, setValues] = useState({});
    const [warnOnUnload, toggleWarnOnUnload] = useState(false);

    useBeforeUnload(warnOnUnload, "Your changes might be discarded");

    const updateValues = (valueArg = {}) =>
        setValues((prevValues) => ({ ...prevValues, ...valueArg }));

    return (
        <ProductIntegrationContext.Provider
            value={{
                values,
                addToValues: updateValues,
                setValues,
                warnOnUnload,
                toggleWarnOnUnload,
            }}
        >
            <ProductIntegrationWrapper>
                <Stepper title="Product Integration">
                    <div title="Customer Details">
                        <CustomerDetails />
                    </div>
                    <div title="Product Details">
                        <ProductDetails />
                    </div>
                    <div title="Payment Merchant">
                        <MerchantConfiguration />
                    </div>

                    <div title="Transaction Charge">
                        <TransactionCharge />
                    </div>
                    <div title="Summary">
                        <IntegrationSummary />
                    </div>
                </Stepper>
            </ProductIntegrationWrapper>
        </ProductIntegrationContext.Provider>
    );
}
