import PropTypes from "prop-types";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import ty from "typy";

import { Box } from "@flexisaf/flexibull2/build/layout";
import { Select } from "@/components";

import Tab from "./Tab";
import Panel from "./Panel";
import { TabsWrapper, TabList } from "./tabsStyles";
import { usePrevious } from "react-use";
import { useViewMode } from "@/utils/hooks/useViewMode";
import { viewModes } from "@/utils/constants";

const TabContext = createContext();
export const useTabsContext = () => useContext(TabContext);

const TAB_SWITCH_MESSAGE = "You may have unsaved changes, are you sure you want to switch ?";

const getTabList = (tabPanels = []) => {
    if (!ty(tabPanels).isArray) throw new Error("Expecting 'children' to be an array of contents");

    return tabPanels.reduce((tabList, panel) => {
        const { title, index, indexKey } = panel.props;
        if (!title) throw new Error("Tab(s) has no title");
        if (!ty(index).isNumber && !index) throw new Error("Tab(s) has no valid index");

        return [...tabList, { title, index, indexKey }];
    }, []);
};

function Tabs(props) {
    const { onTabChange, children: tabPanels, warnOnTabSwitch } = props;

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const previousSelectedTabIndex = usePrevious(selectedTabIndex);

    const tabList = useMemo(() => getTabList(tabPanels), [tabPanels]);

    // Hooked function that is executed whenever tab changes
    useEffect(() => {
        if (previousSelectedTabIndex !== selectedTabIndex && ty(onTabChange).isFunction) {
            const selectedTab = tabList.find((tab) => tab.index === selectedTabIndex);
            onTabChange(selectedTab);
        }
    }, [previousSelectedTabIndex, selectedTabIndex, tabList]);

    const handleTabClick = (index) => {
        let confirmedSwitch = true;
        if (warnOnTabSwitch) confirmedSwitch = window.confirm(TAB_SWITCH_MESSAGE);

        confirmedSwitch && setSelectedTabIndex(index);
    };

    const handleKeyPress = (event) => {
        let next;
        const { key } = event;
        if (key === "ArrowUp") {
            next = selectedTabIndex - 1;
            let first = tabList.length - 1;
            handleNext(first, next, -1);
        }

        if (key === "ArrowDown") {
            next = selectedTabIndex + 1;
            let last = tabList.length;
            handleNext(0, next, last);
        }
    };

    const handleNext = (first, next, last) => {
        let nextToSelect = next === last ? first : next;
        setSelectedTabIndex(nextToSelect);
    };

    const isMobile = useViewMode() === viewModes.MOBILE;

    const processorOptions = useMemo(() => {
        if (!isMobile) return [];
        return tabList.map(({ indexKey, index, title }) => ({
            label: title,
            indexKey,
            value: index,
        }));
    }, [isMobile, tabList]);

    return (
        <TabContext.Provider value={{ selectedTab: selectedTabIndex }}>
            <TabsWrapper>
                {isMobile ? (
                    <Box margin="0 0 1em">
                        <Select
                            label="Payment Processor"
                            value={selectedTabIndex}
                            options={processorOptions}
                            onChange={handleTabClick}
                            style={{ width: "100%" }}
                        />
                    </Box>
                ) : (
                    <TabList onKeyDown={handleKeyPress}>
                        {tabList.map((tab) => (
                            <Tab
                                key={tab.index}
                                index={tab.index}
                                title={tab.title}
                                onClick={handleTabClick}
                                isActive={selectedTabIndex == tab.index}
                            />
                        ))}
                    </TabList>
                )}
                {tabPanels}
            </TabsWrapper>
        </TabContext.Provider>
    );
}

Tabs.propTypes = {
    children: PropTypes.node,
    onTabChange: PropTypes.func,
    warnOnTabSwitch: PropTypes.bool,
};

export default Object.assign(Tabs, {
    Panel,
});
