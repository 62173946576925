export const userMessages = {
    userDeletedMessage: "User account has been deleted successfully",
    userUpdatedMessage: "User has been updated successfully",
    getUserCreatedMessage(email) {
        return `Account created successfully. A confirmation mail will be sent to ${email} for account activation`;
    },
    getDeleteUserMsg: ({ first_name, last_name }) =>
        `Are you sure you want to delete "${first_name} ${last_name}"?  Please be aware that this action is irreversible`,
    getResendConfirmationMsg: ({ email }) =>
        `Account linked to "${email}" has not been verified yet. Do you want to proceed to send confirmation mail?`,
};

export const authMessages = {
    getResendConfirmationMailMessage(email) {
        return `Confirmation mail to ${email} has been sent successfully`;
    },
};
