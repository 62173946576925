import { bearerType, beneficiaryTag } from "./constants";

export const platformOptions = [
    {
        value: "REMITA",
        label: "Remita Payment Platform",
        name: "Remita Platform",
    },
    {
        value: "PAYSTACK",
        label: "Paystack Platform",
        name: "Paystack",
    },
    {
        label: "Interswitch Platform",
        value: "INTERSWITCH",
        name: "Interswitch ",
    },
    {
        value: "DREAMLABS",
        label: "Dreamlabs Platform",
        name: "DREAMLABS",
    },
    {
        label: "Unified Payments Platform",
        value: "UNIFIED_PAYMENTS",
        name: "Unified Payments (UP)",
        isDisabled: true,
    },
];

export const studyModeOptions = [
    { label: "Undergraduate", value: "UNDERGRADUATE" },
    { label: "Postgraduate", value: "POSTGRADUATE" },
];

export const productOptions = [
    {
        label: "SAFSMS",
        value: "SAFSMS",
    },
    {
        label: "SAFAPPLY",
        value: "SAFAPPLY",
    },
    {
        label: "SRMS",
        value: "SRMS",
    },
    {
        label: "OAA",
        value: "OAA",
    },
    {
        label: "SAFCERTS",
        value: "SAFCERTS",
    },
];

export const statusOptions = [
    {
        label: "Success",
        value: "SUCCESS",
    },
    {
        label: "Pending",
        value: "PENDING",
    },
    {
        label: "Failed",
        value: "FAILED",
    },
];

export const pageOptions = [
    {
        label: "10",
        value: "10",
    },
    {
        label: "20",
        value: "20",
    },
    {
        label: "30",
        value: "30",
    },
];

export const customerTypeOptions = [
    {
        label: "Tertiary Institution",
        value: "Tertiary Institution",
    },
    {
        label: "Secondary School",
        value: "Secondary School",
    },
    {
        label: "Primary School",
        value: "Primary School",
    },
];

export const beneficiaryTagOptions = [
    { label: "Client", value: beneficiaryTag.CLIENT },
    { label: "Flexisaf", value: beneficiaryTag.FLEXISAF },
    { label: "Others", value: beneficiaryTag.OTHERS },
];

export const bearerOptions = [
    { label: "Client", value: bearerType.CLIENT },
    { label: "Payer", value: bearerType.PAYER },
];
