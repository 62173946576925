import { useResendConfirmationMutation } from "@/api/auth";
import { useGetRolesQuery } from "@/api/role";
import { useDeleteUserMutation, useGetUsersQuery } from "@/api/user";
import { confirmation } from "@/lib";
import { usePaginationParams, useFilterParams } from "@/utils/hooks/useResourceParams";

export const useUsers = () => {
    const { filters, setFilter, setFilters, clearFilter } = useFilterParams();

    const { pagination, currentPage, setPage, setLimit } = usePaginationParams();

    const { data: usersData, ...getUsersMeta } = useGetUsersQuery({ ...filters, ...pagination });
    const { data: rolesData, ...getRolesMeta } = useGetRolesQuery();
    const [deleteUserReq, deleteUserMeta] = useDeleteUserMutation();
    const [resendConfirmationMailReq, resendConfirmationMailMeta] = useResendConfirmationMutation();

    const loading = {
        isLoadingUsers: getUsersMeta.isLoading,
        isLoadingRoles: getRolesMeta.isLoading,
        isDeletingUser: deleteUserMeta.isLoading,
        isResendingConfirmationMail: resendConfirmationMailMeta.isLoading,
    };

    const updating = {
        isUpdatingUsers: getUsersMeta.isFetching,
        isUpdatingRoles: getRolesMeta.isFetching,
    };

    const errors = {
        usersError: getUsersMeta.error,
        rolesError: getRolesMeta.error,
        deleteUserError: deleteUserMeta.error,
        resendConfirmationMailError: resendConfirmationMailMeta.error,
    };

    const deleteUser = (user) => {
        const action = confirmation(() => deleteUserReq(user.id), {
            title: "User Deletion",
            type: "danger",
            message: getDeleteUserMsg(user),
        });
        action();
    };

    const resendConfirmation = (user) => {
        const action = confirmation(() => resendConfirmationMailReq(user.username), {
            title: "Resend Confirmation",
            type: "success",
            message: getResendConfirmationMsg(user),
        });
        action();
    };

    const actions = {
        setFilter,
        setFilters,
        clearFilter,
        setPage,
        setLimit,
        deleteUser,
        resendConfirmation,
    };

    return {
        usersData,
        rolesData,
        pagination,
        currentPage,
        filters,
        loading,
        updating,
        errors,
        actions,
    };
};

const getDeleteUserMsg = ({ first_name, last_name }) =>
    `Are you sure you want to delete "${first_name} ${last_name}"?  Please be aware that this action is irreversible`;

const getResendConfirmationMsg = ({ email }) =>
    `Account linked to "${email}" has not been verified yet. Do you want to proceed to send confirmation mail?`;
