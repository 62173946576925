import styled from "styled-components";

export const LoadableWrapper = styled.div`
    width: ${(p) => p.width};
    height: ${(p) => p.height};
    display: flex;
    justify-content: center;
    align-items: center;
`;

LoadableWrapper.defaultProps = {
    width: "100%",
    height: "auto",
};

export const ErrorOccuredWrapper = styled.div`
    text-align: center;
    & .message {
        margin-bottom: 1em;
    }
`;
