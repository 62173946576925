import { theme } from "@/style";
import styled from "styled-components";

export const StyledTagWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: ${(p) => p.width};
    border-radius: 0.8em;
    background: white;
    border: 2px solid ${theme.PrimaryColor};
    padding: 0.5em 1em;
    &:not(:last-child) {
        margin-right: 1.2em;
    }
    .text {
        text-overflow: ellipisis;
        white-space: nowrap;
        overflow-x: hidden;
    }

    .icon {
        cursor: pointer;
        :hover {
            color: red;
        }
    }
`;

StyledTagWrapper.defaultProps = {
    width: "9em",
};
