import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "@/store/authSlice";

const isAuthorized = (userPrivilegesSet, privileges, anyOf) => {
    const comparator = anyOf ? "some" : "every";
    return privileges[comparator]((privilege) => userPrivilegesSet.has(privilege));
};

const useAuthorization = () => {
    const { user } = useSelector(selectAuth);
    const privilegesSet = useMemo(() => new Set(user.rawPrivileges), [user.rawPrivileges]);

    const hasPrivilege = useCallback(
        (privilege, anyOf) => {
            const privilegesList = Array.isArray(privilege) ? privilege : [privilege];
            return isAuthorized(privilegesSet, privilegesList, anyOf);
        },
        [privilegesSet]
    );

    return {
        hasPrivilege,
    };
};

export default useAuthorization;
