import { createApi } from "@reduxjs/toolkit/query/react";

import config from "@/utils/config";
import { createBaseQuery } from "@/lib";
import { methods } from "@/utils/constants";
import cacher from "@/utils/rtkQueryCacheUtils";

export const tagType = "SCHOOL";

const schoolApi = createApi({
    baseQuery: createBaseQuery({ baseURL: `${config.apiURL}/schools` }),
    tagTypes: [cacher.defaultTags, tagType],
    reducerPath: "schoolApi",
    endpoints: (builder) => ({
        getSchool: builder.query({
            query: (data) => ({ url: "", method: methods.GET, data }),
        }),
    }),
    refetchOnMountOrArgChange: true,
});

export const { useGetSchoolQuery } = schoolApi;
export default schoolApi;
