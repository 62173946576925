import { TextArea as FlexiTextArea } from "@flexisaf/flexibull2/build/textarea";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";

export default function TextArea(props) {
    const {
        name,
        label,
        placeholder,
        type = "text",
        defaultValue = "",
        rules = {},
        ...restProps
    } = props;
    const { field, fieldState } = useController({ name, defaultValue, rules });

    return (
        <FlexiTextArea
            name={name}
            label={label}
            type={type}
            placeholder={placeholder}
            onChange={field.onChange}
            value={field.value}
            error={fieldState.error?.message}
            {...restProps}
        />
    );
}

TextArea.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    rules: PropTypes.object,
};
