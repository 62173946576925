import styled from "styled-components";

export const StyledOverlay = styled.section`
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
    background: blue;
    color: red;
`;
