import { Select as FlexiSelect } from "@flexisaf/flexibull2/build/select";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import ty from "typy";

const getSelectValue = (options, value) => {
    if (!ty(value).isNumber && !ty(value).isBoolean && !value) return "";

    if (ty(value).isObject) {
        return getValueFromObject(value);
    }

    const isNumberOrBoolOrString = ty(value).isString || ty(value).isBoolean || ty(value).isNumber;
    if (isNumberOrBoolOrString) {
        return options?.find((opt) => opt.value === value);
    }

    if (ty(value).isArray) {
        return value.map((currentValue) => getSelectValue(options, currentValue));
    }
};

const getValueFromObject = (valueObj) => {
    if (!valueObj.label || !valueObj.value)
        console.warn("Object passed does not contain 'label' or 'value' field"); //eslint-disable-line
    return valueObj;
};

export default function Select(props) {
    const { name, label, rules = {}, options, defaultValue, valueAsObject, ...restProps } = props;
    const { field, fieldState } = useController({ name, defaultValue, rules });

    const handleChange = (selected) => {
        if (!selected) return null;
        if (restProps.isMulti) {
            const selectedValues = selected.map(({ value }) => value);
            return field.onChange(selectedValues, selected);
        }
        const selectedForChange = valueAsObject ? selected : selected.value;
        field.onChange(selectedForChange, selected);
    };

    return (
        <FlexiSelect
            name={name}
            label={label}
            value={getSelectValue(options, field.value)}
            onChange={handleChange}
            error={fieldState.error?.message}
            options={options}
            width="100%"
            {...restProps}
        />
    );
}

Select.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValue: PropTypes.string,
    rules: PropTypes.object,
    valueAsObject: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object),
};
