import { createApi } from "@reduxjs/toolkit/query/react";

import config from "@/utils/config";
import { createBaseQuery } from "@/lib";
import cacher from "@/utils/rtkQueryCacheUtils";

import { contactTypes, methods } from "@/utils/constants";
import { Notify } from "@flexisaf/flexibull2/build/notify";
import { userMessages as messages } from "@/utils/mesages";
import { getErrorOrFallbackMsg } from "@/utils/help";

const userBaseUrl = `${config.umsURL}/users/products/${config.umsProductId}/organisations/${config.umsOrganisation}`;

const userApi = createApi({
    baseQuery: createBaseQuery({ baseURL: userBaseUrl }),
    tagTypes: [...cacher.defaultTags, "USER"],
    reducerPath: "userApi",
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: (data) => ({
                url: "",
                data,
            }),
            transformResponse: transformUsers,
            providesTags: ["USER"],
        }),

        getUser: builder.query({
            query: (userId) => ({
                url: `/${encodeURIComponent(userId)}`,
            }),
            transformResponse: transformUser,
            providesTags: ["USER"],
        }),
        createUser: builder.mutation({
            query: (data) => ({
                url: "",
                data: transformUserRequest(data),
                method: methods.POST,
            }),
            invalidatesTags: ["USER"],
            onQueryStarted(reqBody, { queryFulfilled: qf }) {
                qf.then(() => Notify(messages.getUserCreatedMessage(reqBody.email))).catch(
                    ({ error }) => Notify(getErrorOrFallbackMsg(error), { status: "error" })
                );
            },
        }),
        updateUser: builder.mutation({
            query: ({ id, ...restData }) => ({
                url: `/${id}`,
                data: transformUserRequest(restData),
                method: methods.PATCH,
            }),
            invalidatesTags: ["USER"],
            onQueryStarted(reqBody, { queryFulfilled: qf }) {
                qf.then(() => Notify(messages.userUpdatedMessage)).catch(({ error }) =>
                    Notify(getErrorOrFallbackMsg(error), { status: "error" })
                );
            },
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `/${id}`,
                method: methods.DELETE,
            }),
            invalidatesTags: ["USER"],
            onQueryStarted(reqBody, { queryFulfilled: qf }) {
                qf.then(() => Notify(messages.userDeletedMessage)).catch(({ error }) =>
                    Notify(getErrorOrFallbackMsg(error), { status: "error" })
                );
            },
        }),
        resetPassword: builder.mutation({
            query: (data) => ({
                url: "/password/reset",
                data,
            }),
        }),
    }),
});

function transformUsers(payload) {
    const { entities: users, ...meta } = payload;
    const entities = users.map(transformUser);
    return { entities, meta };
}

function transformUser(user) {
    const { contacts, roles, id, username, ...restUser } = user;
    const emailContact = contacts?.find((cnt) => getContactType(cnt) === contactTypes.EMAIL);
    const phone = contacts?.find((cnt) => getContactType(cnt) === contactTypes.PHONE)?.contact;
    const { contact: email, verified } = emailContact || {};

    return {
        id,
        first_name: restUser.first_name || restUser.firstName,
        last_name: restUser.last_name || restUser.lastName,
        role: roles[0]?.role,
        email,
        username,
        verified,
        phone,
    };
}

function getContactType(cnt) {
    return cnt.contact_type || cnt.contactType;
}

function transformUserRequest(userReq) {
    const { email, phone, role, ...restUser } = userReq;
    const contacts = [{ contact_type: contactTypes.EMAIL, contact: email }];
    if (phone) {
        contacts.push({ contact_type: contactTypes.PHONE, contact: phone });
    }
    const roles = [{ admin_lvl: "", id: role.id }];

    return {
        ...restUser,
        request_password_change: true,
        roles,
        contacts,
        username: email,
    };
}

export const {
    useGetUsersQuery,
    useGetUserQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
} = userApi;

export default userApi;
