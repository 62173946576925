import PropTypes from "prop-types";
import { useState } from "react";
import { IconSearch } from "@/components/icons";
import { StyledSearchBar } from "./SearchBar.styled";
import { useDebounce } from "react-use";

export default function SearchBar(props) {
    const { onChange, onClear, placeholder, value, debounceInterval = 0, wide } = props;
    const [searchValue, setSearchValue] = useState(value || "");

    useDebounce(() => searchValue && onChange(searchValue), debounceInterval, [searchValue]);

    const handleChange = (event) => {
        const { value } = event.target;
        setSearchValue(value);
        if (!value) {
            onClear && onClear();
        }
    };

    return (
        <StyledSearchBar wide={wide}>
            <input
                type="search"
                value={searchValue}
                placeholder={placeholder}
                className="search-input"
                onChange={handleChange}
            />
            <IconSearch className="icon" />
        </StyledSearchBar>
    );
}

SearchBar.propTypes = {
    onChange: PropTypes.func.isRequired,
    onClear: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    debounceInterval: PropTypes.number,
    wide: PropTypes.bool,
};
