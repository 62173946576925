import persistStore from "redux-persist/es/persistStore";
import { PersistGate } from "redux-persist/integration/react";

import { Layout } from "@flexisaf/flexibull2";
import { Provider } from "react-redux";
import { Outlet } from "react-router-dom";
import store from "./store";
import { theme } from "./style";

const persistedStore = persistStore(store);

export default function App() {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistedStore}>
                <Layout theme={theme}>
                    <Outlet />
                </Layout>
            </PersistGate>
        </Provider>
    );
}
