import PropTypes from "prop-types";
import { useCallback } from "react";
import { useGetPlatformBanksAsOptionsQuery } from "@/api/bank";

import { Spacer, Grid as FlexiGrid, Box } from "@flexisaf/flexibull2/build/layout";
import { Text } from "@flexisaf/flexibull2/build/typo";
import { Button } from "@flexisaf/flexibull2/build/button";

import { HookForm as Form } from "@/components";
import { IconDelete, IconAdd } from "@/components/icons";
import { theme } from "@/style";
import { bearerType, splitTypes } from "@/utils/constants";
import { bearerOptions, beneficiaryTagOptions } from "@/utils/uiOptions";

import {
    DeleteButton,
    BeneficiaryWrapper,
} from "./create-product-integration/productIntegrationStyles";

export const BENEFICIARY_FIELD_NAME = "beneficiaries";
export const defaultSchema = {
    name: "name",
    accountNumber: "accountNumber",
    bank: "bank",
    amount: "amount",
    tag: "tag",
    deductChargeOn: "deductChargeOn",
    bearer: "bearer",
};

export const newBeneficiary = {
    [defaultSchema.name]: "",
    [defaultSchema.accountNumber]: "",
    [defaultSchema.bank]: "",
    [defaultSchema.amount]: 0,
    [defaultSchema.tag]: "",
    [defaultSchema.deductChargeOn]: false,
    [defaultSchema.bearer]: bearerType.CLIENT,
};

const getCustomizedIconAdd = ({ disabled }) => (
    <IconAdd stroke={disabled ? "lightgray" : theme.PrimaryColor} width="1.4em" height="1.4em" />
);

const getBeneficiaryShareLabel = (splitType) =>
    splitType === splitTypes.PERCENTAGE ? "Percent(%)" : "Share";

export default function Beneficiary(props) {
    const { fieldsIndex, onRemove, index, splitType, platform, schema = defaultSchema } = props;
    const bankOptionsQuery = useGetPlatformBanksAsOptionsQuery({ platform });
    const withFieldsIndex = useCallback((pathName) => `${fieldsIndex}.${pathName}`, [fieldsIndex]);
    const canRenderRelativeSplitHint = splitType === splitTypes.RELATIVE && index === 0;
    const splitAmountType = canRenderRelativeSplitHint
        ? "text"
        : splitType === splitTypes.PERCENTAGE
        ? "number"
        : "currency";

    return (
        <BeneficiaryWrapper>
            <Text size="1.2em" bold>
                Beneficiary {index + 1}
            </Text>
            <Spacer space="30" />
            <FlexiGrid default="repeat(3, 1fr)" gap="2.5em .8em" className="fields">
                <Form.Input label="Name" name={withFieldsIndex(schema.name)} />
                <Form.Input label="Account Number" name={withFieldsIndex(schema.accountNumber)} />
                <Form.Select
                    label="Bank"
                    name={withFieldsIndex(schema.bank)}
                    options={bankOptionsQuery.data}
                    getOptionValue={(bank) => bank.code}
                    valueAsObject
                    getOptionLabel={(bank) => bank.name}
                    isLoading={bankOptionsQuery.isLoading}
                />
                <Form.Input
                    label={getBeneficiaryShareLabel(splitType)}
                    name={withFieldsIndex(schema.amount)}
                    type={splitAmountType}
                    defaultValue={canRenderRelativeSplitHint ? "BALANCE" : ""}
                    disabled={canRenderRelativeSplitHint}
                />
                <Form.Select
                    label="Tag"
                    name={withFieldsIndex(schema.tag)}
                    options={beneficiaryTagOptions}
                />
                <Form.Select
                    label="Bearer"
                    name={withFieldsIndex(schema.bearer)}
                    options={bearerOptions}
                />
                <Form.Switch
                    label={<Text size="10.5px">Charge this account</Text>}
                    name={withFieldsIndex(schema.deductChargeOn)}
                />
                <DeleteButton onClick={onRemove}>
                    <IconDelete />
                    <span className="text"> Remove </span>
                </DeleteButton>
            </FlexiGrid>
            {canRenderRelativeSplitHint && (
                <Box margin=".5em 0">
                    <small>
                        In relative split payment, the first beneficiary gets the balance (after the
                        other beneficiaries shares has been deducted), or it gets the entire amount
                        if there are no other beneficiaries.
                    </small>
                </Box>
            )}
        </BeneficiaryWrapper>
    );
}

Beneficiary.propTypes = {
    fieldsIndex: PropTypes.string.isRequired,
    platform: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onRemove: PropTypes.func.isRequired,
    splitType: PropTypes.string.isRequired,
    schema: PropTypes.object,
};

export function AddBeneficiaryButton(props) {
    const { isDisabled, onClick } = props;
    return (
        <Button
            iconRight={getCustomizedIconAdd({ disabled: isDisabled })}
            pale
            round
            disabled={isDisabled}
            style={{
                cursor: isDisabled ? "not-allowed" : "pointer",
            }}
            onClick={onClick}
        >
            Add Beneficiary
        </Button>
    );
}

AddBeneficiaryButton.propTypes = {
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func,
};
