import PropTypes from "prop-types";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuth } from "@/store/authSlice";
import { baseRoute } from "@/routes/allRoutes";

export default function ProtectedRoute(props) {
    const { redirectPath = baseRoute, children } = props;
    const { user } = useSelector(selectAuth);
    if (!user) return <Navigate to={redirectPath} />;
    return children ? children : <Outlet />;
}

ProtectedRoute.propTypes = {
    redirectPath: PropTypes.string,
    children: PropTypes.node,
};
