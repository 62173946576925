import { isRejectedWithValue } from "@reduxjs/toolkit";

/* eslint-disable-next-line */
const errorMiddleware = (api) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        // eslint-disable-next-line no-console
        console.error(action.error);
    }
    return next(action);
};

export default errorMiddleware;
 