import { css } from "styled-components";

export const resetButton = () => css`
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    &:disabled {
        cursor: not-allowed;
    }
`;
