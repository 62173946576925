import styled, { css } from "styled-components";

import { theme } from "@/style";
import { DashCard } from "@flexisaf/flexibull2/build/dashcard";

export const NavCardWrapper = styled(DashCard)`
    line-height: 2em;
    border-radius: 0px;
    min-width: 230px;
    min-height: 200px;
    padding: 3em 3.125em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    .card-section {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        height: 100%;
    }
    ${(p) =>
        p.noShadow &&
        css`
            box-shadow: none;
            &:hover {
                box-shadow: none;
            }
        `}

    ${(p) =>
        p.bordered &&
        css`
            border: 1px solid ${theme.PrimaryBorderColor};
        `}
    & .title {
        font-size: 16px;
        margin-bottom: 0.6em;
        font-weight: 700;
    }
    & .description {
        color: ${theme.PrimaryGrey};
        max-height: 8em;
        margin-bottom: 1.25em;
    }
`;
