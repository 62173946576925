import PropTypes from "prop-types";

import { Text } from "@flexisaf/flexibull2/build/typo";

import { Link } from "@/components";
import { IconArrowRight } from "@/components/icons";

import { OverViewCardWrapper } from "./OverViewStyles";
import { Box } from "@flexisaf/flexibull2/build/layout";
import { theme } from "@/style";

export default function Card(props) {
    const { title, value, linkText, linkPath, description, highlighted } = props;
    return (
        <OverViewCardWrapper highlighted={highlighted}>
            <Text bold size="1em" className="title">
                {title}
            </Text>
            <Text color={theme.PrimaryColor} className="value">
                {value}
            </Text>
            {linkText ? (
                <Link to={linkPath}>
                    <Box
                        display="flex"
                        style={{
                            alignItems: "center",
                        }}
                    >
                        <span className="link">{linkText}</span>

                        <IconArrowRight className="icon icon__link" />
                    </Box>
                </Link>
            ) : (
                <Text className="description">{description}</Text>
            )}
        </OverViewCardWrapper>
    );
}

Card.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    description: PropTypes.string,
    highlighted: PropTypes.bool,
    linkPath: PropTypes.string,
    linkText: PropTypes.string,
};
