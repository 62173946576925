import { noop } from "lodash";
import PropTypes from "prop-types";
import ty from "typy";

import ActionItem from "./ActionItem";
import { StyledActionsWrapper } from "./tableStyles";

export default function Actions(props) {
    const { actions, item } = props;

    return (
        <StyledActionsWrapper>
            {actions.map((action) => {
                const isHidden = ty(action.hidden).isFunction ? action.hidden(item) : action.hidden;
                if (isHidden) return null;

                return (
                    <ActionItem
                        key={action.label}
                        icon={action.icon}
                        title={action.title}
                        fn={(evt) => (ty(action.fn).isFunction ? action.fn(item, evt) : noop)}
                        disabled={
                            ty(action.disabled).isFunction ? action.disabled(item) : action.disabled
                        }
                    />
                );
            })}
        </StyledActionsWrapper>
    );
}

Actions.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.object),
    item: PropTypes.object,
};
