import noop from "lodash/noop";
import PropTypes from "prop-types";
import ty from "typy";

import { IconBack, IconNext } from "@/components/icons";

import { useStepper } from "./Stepper";
import { StepButton, StepButtonsWrapper } from "./stepperStyles";

export default function StepButtons(props) {
    const { onNext, onPrev, onSubmit } = props;
    const stepper = useStepper();

    const handlePrevClick = () => {
        if (ty(onPrev).isFunction) {
            onPrev();
            return;
        }
        stepper.handlePrev();
    };

    const handleNextClick = () => {
        if (ty(onNext).isFunction) {
            onNext();
            return;
        }
        stepper.handleNext();
    };
    return (
        <StepButtonsWrapper>
            <StepButton prev onClick={handlePrevClick} disabled={stepper.view === 0}>
                <IconBack style={{ marginRight: "1em" }} /> Previous
            </StepButton>
            {stepper.view >= stepper.totalSteps - 1 ? (
                <StepButton next onClick={ty(onSubmit).isFunction ? onSubmit : noop}>
                    Submit
                </StepButton>
            ) : (
                <StepButton next onClick={handleNextClick}>
                    <span style={{ marginRight: "1em" }}>Next</span> <IconNext />
                </StepButton>
            )}
        </StepButtonsWrapper>
    );
}

StepButtons.propTypes = {
    onPrev: PropTypes.func,
    onNext: PropTypes.func,
    onSubmit: PropTypes.func,
};
