import { createApi } from "@reduxjs/toolkit/query/react";

import config from "@/utils/config";
import { createBaseQuery } from "@/lib";
import { methods } from "@/utils/constants";
import cacher from "@/utils/rtkQueryCacheUtils";
import { productOptions } from "@/utils/uiOptions";

export const tagType = "PRODUCT";

const productApi = createApi({
    baseQuery: createBaseQuery({ baseURL: `${config.apiURL}/products` }),
    tagTypes: [cacher.defaultTags, tagType],
    reducerPath: "productApi",
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: (data) => ({ url: "/", method: methods.GET, data }),
            providesTags: cacher.providesList(tagType, {
                nestedKey: "entities",
            }),
        }),
        getProduct: builder.query({
            query: (id) => ({ url: `/${id}`, method: methods.GET }),
            providesTags: cacher.cacheById(tagType),
        }),
        getProductAsOptions: builder.query({
            queryFn: () => ({ data: productOptions }),
            transformResponse: (data) =>
                data?.map(({ id: value, name: label }) => ({ label, value })),
        }),
        addProduct: builder.mutation({
            query: (data) => ({
                url: "/",
                method: methods.POST,
                data,
            }),
            invalidatesTags: cacher.invalidatesList(tagType),
        }),
        editProduct: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/${id}`,
                method: methods.POST,
                data,
            }),
            invalidatesTags: cacher.invalidatesList(tagType),
        }),
        deleteProduct: builder.mutation({
            query: (id) => ({
                url: `${id}`,
                method: methods.DELETE,
            }),
        }),
    }),
    refetchOnMountOrArgChange: true,
});

export const {
    useGetProductsQuery,
    useGetProductQuery,
    useGetProductAsOptionsQuery,
    useAddProductMutation,
    useEditProductMutation,
    useDeleteProductMutation,
} = productApi;
export default productApi;
