import { Checkbox as FlexiCheckbox } from "@flexisaf/flexibull2/build/checkbox";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";

export default function Checkbox(props) {
    const { name, label, rules = {}, defaultChecked = false, ...restProps } = props;
    const { field, fieldState } = useController({ name, defaultValue: defaultChecked, rules });

    return (
        <FlexiCheckbox
            name={name}
            label={label}
            onChange={(event) => field.onChange(event.target.checked)}
            checked={field.value}
            error={fieldState.error?.message}
            {...restProps}
        />
    );
}

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultChecked: PropTypes.string,
    rules: PropTypes.object,
};
