import { theme, styleUtils } from "@/style";
import { breakpoints } from "@/utils/constants";
import styled, { css } from "styled-components";

export const StepButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1em;
`;

export const StepButton = styled.button`
    ${styleUtils.resetButton()}
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4em;
    transition: 0.2s ease-in-out;
    padding: 1.2em 0.8em;

    @media ${breakpoints.TABLET} {
        padding: 2em;
    }

    & svg {
        width: 1.2em;
        height: 1.2em;
    }

    &:disabled {
        opacity: 0.6;
    }

    /* Variants */
    ${(p) =>
        p.next &&
        css`
            background-color: ${theme.PrimaryColor};
            color: white;

            & svg {
                stroke: white;
            }
        `}
`;
