import PropTypes from "prop-types";
import { useRef, useEffect } from "react";
import { createPortal } from "react-dom";

export default function Portal(props) {
    const { id, children } = props;
    const target = usePortal(id);
    return createPortal(children, target);
}

const createParentElement = (id) => {
    const parentElement = document.createElement("div");
    parentElement.setAttribute("id", id);
    return parentElement;
};

function usePortal(id) {
    const elemRef = useRef();

    function getElement() {
        if (!elemRef.current) {
            elemRef.current = document.createElement("div");
        }
        return elemRef.current;
    }

    useEffect(() => {
        const container = document.getElementById(id);
        const noExistingParentBefore = Boolean(container);
        const parentElement = container || createParentElement(id);
        if (noExistingParentBefore) {
            document.body.appendChild(parentElement);
        }
        parentElement.appendChild(elemRef.current);

        return () => {
            elemRef.current?.remove();
            parentElement.remove();
        };
    }, []);

    return getElement();
}

Portal.propTypes = {
    id: PropTypes.string,
    children: PropTypes.node,
};
