import PropTypes from "prop-types";
import { IconTick, IconUntick } from "../icons";

import { TabWrapper } from "./tabsStyles";

export default function Tab(props) {
    const { index, title, onClick, isActive } = props;
    return (
        <TabWrapper role="presentation" aria-label={`tab-${title}`} isActive={isActive}>
            <button onClick={() => onClick(index)} tabIndex={isActive ? 0 : -1}>
                <span className="text">{title} </span>
                {isActive ? <IconTick /> : <IconUntick />}
            </button>
        </TabWrapper>
    );
}

Tab.propTypes = {
    index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
};
