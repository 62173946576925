import PropTypes from "prop-types";
import { theme } from "@/style";

import { Box } from "@flexisaf/flexibull2/build/layout";
import { Text } from "@flexisaf/flexibull2/build/typo";
import { Spacer } from "@flexisaf/flexibull2/build/layout";

export default function EditSection(props) {
    const { title, children } = props;

    return (
        <Box>
            <Box style={{ paddingBottom: "22px", borderBottom: `4px solid ${theme.PrimaryColor}` }}>
                <Text> {title}</Text>
            </Box>

            <Spacer space={32} />

            <Box>{children}</Box>
        </Box>
    );
}

EditSection.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
};
