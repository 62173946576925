import { theme } from "@/style";
import styled from "styled-components";

export const NetworkIndicatorWrapper = styled.div`
    position: fixed;
    z-index: 9000;
    bottom: 0;
    width: 100vw;
    padding: 0.5em 1em;
    text-align: center;
    background: ${(p) => (p.isOnline ? theme.PrimaryGreen : "black")};
    color: ${(p) => (p.isOnline ? "white" : theme.PrimaryRed)};
`;
