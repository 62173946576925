import styled, { css } from "styled-components";
import theme from "@/style/theme";

export const StyledSearchBar = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #e6e7eb;
    border-radius: 8px;
    background: #ffffff;
    ${(p) =>
        p.wide &&
        css`
            padding: 1em 1.5em;
        `}
    .search-input {
        border: none;
        width: 100%;
        background: none;
        &::placeholder {
            color: ${theme.PlaceholderColor};
        }
        &:focus {
            outline: none;
            border: none;
        }
    }

    .icon {
        width: 1.25em;
        height: 1.25em;
    }
`;
