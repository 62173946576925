import mf from "minifaker";

export const customerType = () =>
    mf.arrayElement(["Tertiary Institution", "Secondary School", "Primary School", "Organization"]);
export const id = () => mf.uuid.v4();

// const makeResponse = (payload) => ({ data: payload });

const getParams = (req) => {
    const getParam = (name) => req.url.searchParams.get(name);
    const limit = getParam("limit") || 20;
    const start = getParam("start") || 0;
    return { limit, start };
};
export const makeResponseList = (req, payload) => {
    const params = getParams(req);
    params.total = payload?.length;
    return { entities: payload, ...params };
};

export const entityFactory = (entityList = []) => {
    let data = entityList;

    const get = (id) => (id ? data.find((item) => item.id === id) : data);
    const add = (body) => {
        data = [...data, body];
    };
    const patch = (id, body) =>
        (data = data.map((item) => (item.id === id ? { ...item, ...body } : item)));
    const remove = (data, id) => (data = data.filter((item) => item.id === id));

    return { get, add, patch, remove };
};

export const formatNumber = (number, opts = { locale: "en-NG" }) =>
    new Intl.NumberFormat(opts.locale).format(number);
