import PropTypes from "prop-types";
import styled from "styled-components";

import { Box, Spacer } from "@flexisaf/flexibull2/build/layout";
import { Text } from "@flexisaf/flexibull2/build/typo";
import { DropDown } from "@flexisaf/flexibull2/build/dropdown";
import { IconArrowDown } from "@/assets/icons/misc-icons";
import { hasItems } from "@/utils/help";

const IconWrapper = styled.span`
    margin-right: 0.5em;
    svg {
        width: 1em;
        height: 1em;
    }
`;

export default function AppHeader(props) {
    const { user, actions = [] } = props;
    return (
        <Box display="flex" vAlign pad="0 1em">
            <Box>
                <Text bold block size="1.15em" margin="0 0 0 1em">
                    {user.fullName}
                </Text>
                <Text block size="1em">
                    {user.username}
                </Text>
            </Box>
            <Spacer direction="horizontal" space="12" />
            {hasItems(actions) > 0 && (
                <DropDown label={<IconArrowDown />} trigger="click" menuAlign="right bottom">
                    {actions.map(({ name, icon, fn }) => (
                        <li
                            onClick={fn}
                            key={name}
                            title={name}
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <IconWrapper>{icon}</IconWrapper> {name}
                        </li>
                    ))}
                </DropDown>
            )}
        </Box>
    );
}

AppHeader.propTypes = {
    user: PropTypes.object,
    actions: PropTypes.array,
};
