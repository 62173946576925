import { UNAUTHORIZED, UNKNOWN_ERROR } from "./constants";
import ty from "typy";

const LIST = "LIST";
const defaultTags = [UNKNOWN_ERROR, UNAUTHORIZED];

const concatErrorToCache = (exisitingCache = [], error) => {
    if (ty(error).isObject && error.status && error.status === 401)
        return [...exisitingCache, UNAUTHORIZED];
    return [...exisitingCache, UNKNOWN_ERROR];
};

const providesList =
    (type, { nestedKey, idKey } = {}) =>
    (payload, error) => {
        if (payload) {
            /**
             * If nestedKey is provided, we assume the actual data is accessible via the key.
             */
            const actualData = nestedKey ? payload[nestedKey] : payload;
            const idProp = idKey ? idKey : "id";
            const tags = [
                { type, id: LIST },
                ...actualData.map((item) => ({ type, id: item[idProp] })),
            ];
            return tags;
        }
        return concatErrorToCache([{ type, id: LIST }], error);
    };

const invalidatesList = (type) => () => ({ type, id: LIST });

const cacheById = (type) => (payload, error, id) => [{ type, id }];
const cacheByIdProperty = (type) => (payload, error, arg) => [{ type, id: arg.id }];

const invalidateByError = (errorCase) => () => [errorCase];

export default {
    defaultTags,
    providesList,
    invalidatesList,
    invalidateByError,
    cacheById,
    cacheByIdProperty,
};
