import { theme } from "@/style";
import styled, { css } from "styled-components";

export const PhoneInputWrapper = styled.div`
    display: grid;
    grid-template-columns: 30% 70%;
    ${(p) =>
        p.isDisabled &&
        css`
            opacity: 0.8;
        `}

    ${(p) =>
        p.hasError &&
        css`
            & .flex__control,
            & .flexi-input-affix-wrapper {
                border-color: ${theme.PrimaryGrey};
            }
        `}
    .flexi__control {
        ${(p) => (p.isDisabled ? theme.PrimaryGrey : theme.TertiaryGrey)};
    }
`;
