import PropTypes from "prop-types";
import { StyledTagWrapper } from "./filterStyles.js";

export default function FilterTag(props) {
    const { name, label, title, clearFilter, width } = props;
    const handleClear = () => {
        clearFilter(name);
    };

    return (
        <StyledTagWrapper title={title || label} width={width}>
            <div className="text">{label}</div>
            <div role="button" onClick={handleClear}>
                <i className="icon flexibull-cancel" />
            </div>
        </StyledTagWrapper>
    );
}

FilterTag.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    title: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    clearFilter: PropTypes.func.isRequired,
};
