import { useCopyToClipboard } from "react-use";
import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { Notify } from "@flexisaf/flexibull2/build/notify";
import { Box, Spacer } from "@flexisaf/flexibull2/build/layout";
import { Text } from "@flexisaf/flexibull2/build/typo";
import { Button } from "@flexisaf/flexibull2/build/button";

import { useCreateProductIntegrationMutation } from "@/api/productIntegration";
import { useGetSchoolQuery } from "@/api/school";
import { Stepper } from "@/components";
import { IconCopy } from "@/components/icons";
import { useViewMode } from "@/utils/hooks/useViewMode";
import { theme } from "@/style";
import { formatCurrency, getSchoolAsOptions, hasItems } from "@/utils/help";
import { productOptions } from "@/utils/uiOptions";
import { viewModes } from "@/utils/constants";
import { snakeCaseToCapital } from "@/utils/help";
import { useStepper } from "@/components/stepper/Stepper";
import { splitTypes } from "@/utils/constants";

import { ContentBaseWrapper } from "./productIntegrationStyles";
import { useProductIntegration, transformProductIntegration } from "../productIntegrationUtils";
import { useNavigate } from "react-router-dom";
import { productIntegrationRoutes } from "@/routes/allRoutes";
import { formatNumber } from "@/mock/utils";

const iconCopy = <IconCopy width="1.2em" height="1.2em" stroke={theme.PrimaryGrey} />;
const getBeneficiaryColumns = (splitType) => [
    "Name",
    "Bank Name",
    splitType === splitTypes.PERCENTAGE ? "Percentage(%)" : "Amount(₦)",
];

export default function IntegrationSummary() {
    const { data: schools } = useGetSchoolQuery();
    const { values } = useProductIntegration();
    const { handlePrev } = useStepper();

    const [createProductIntegration, { isLoading: isSubmitting, error: submissionError, data }] =
        useCreateProductIntegrationMutation();
    const [apiError, setApiError] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if (submissionError) setApiError(submissionError);
    }, [submissionError]);

    useEffect(() => {
        if (data) {
            navigate(`${productIntegrationRoutes.index}`, { state: { spotlightId: data.id } });
        }
    }, [data]);

    const viewMode = useViewMode();
    const isMobile = viewMode === viewModes.MOBILE;

    const parsedValues = useMemo(() => {
        const { schoolId, productId, charge = 0, beneficiaries, ...restValues } = values;
        const schoolName =
            getSchoolAsOptions(schools).find((sch) => sch.value === schoolId)?.label || "";
        const productName =
            productOptions.find((product) => product.value === productId)?.label || "";

        const formattedBeneficiaries = beneficiaries?.map(({ name, bank, amount, bearer }) => ({
            name,
            bankName: bank.name,
            amount: formatNumber(amount),
            bearer,
        }));
        return {
            ...restValues,
            beneficiaries: formattedBeneficiaries,
            charge: formatCurrency(charge),
            schoolId,
            schoolName,
            productName,
        };
    }, [values, schools]);

    const message = useMemo(() => {
        if (isSubmitting) return <Box> Submission in progress...</Box>;

        if (apiError)
            return (
                <Text block color={theme.PrimaryRed}>
                    {apiError.message}
                </Text>
            );
        if (data) return <IntegrationIdPreviewer id={data.id} />;

        return null;
    }, [isSubmitting, apiError, data]);

    return (
        <ContentBaseWrapper>
            <div className="content">
                <Section title="Customer Details">
                    <Field
                        title="Name"
                        value={`${parsedValues.schoolName} (${parsedValues.schoolId})`}
                    />
                    <Field title="Product" value={parsedValues.productName} />
                </Section>

                <Spacer space="40" />

                <Section title="Transaction Setup">
                    <Field title="Merchant" value={snakeCaseToCapital(parsedValues.platform)} />
                    <Field title="Charge (₦)" value={parsedValues.charge} />
                    {parsedValues.splitType && (
                        <Field
                            title="Type"
                            value={` ${snakeCaseToCapital(parsedValues.splitType)} Split`}
                        />
                    )}
                </Section>
                <Spacer space="40" />
                {hasItems(parsedValues.beneficiaries) && (
                    <Section title="Beneficiaries" gap={`2em ${isMobile ? "4em" : null} `}>
                        <BeneficiariesTable
                            beneficiaries={parsedValues.beneficiaries}
                            columns={getBeneficiaryColumns(parsedValues.splitType)}
                        />
                    </Section>
                )}
                <Spacer space="40" />
                {message}
            </div>
            <Stepper.StepButtons
                onPrev={() => {
                    setApiError(null);
                    handlePrev();
                }}
                onSubmit={() => {
                    createProductIntegration(transformProductIntegration(values));
                }}
            />
        </ContentBaseWrapper>
    );
}

function Section(props) {
    const { title, children } = props;
    return (
        <section>
            <Text
                block
                size="1.2em"
                bold
                style={{
                    paddingBottom: "1em",
                    borderBottom: `.5px solid ${theme.PrimaryGrey}`,
                    marginBottom: "2em",
                }}
            >
                {title}
            </Text>
            <Box>{children}</Box>
        </section>
    );
}

Section.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
};

function Field(props) {
    const { title, value } = props;
    const isStringTitle = typeof title === "string";
    return (
        <Box display="flex" style={{ marginBottom: "1em" }}>
            {isStringTitle ? (
                <Box display="inline-block" width="7em" style={{ fontWeight: "bold" }}>
                    {title}:
                </Box>
            ) : (
                title
            )}
            <Box display="inline-block">{value}</Box>
        </Box>
    );
}

Field.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

function BeneficiariesTable({ beneficiaries = [], columns }) {
    return (
        <table style={{ textAlign: "left", width: "100%" }}>
            <thead>
                <tr style={{ color: theme.PrimaryGrey, marginBottom: "2em" }}>
                    {columns.map((col) => (
                        <th key={col}> {col} </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {beneficiaries.map(({ name, bankName, amount }, index) => (
                    <tr key={index} style={{ marginBottom: "1em" }}>
                        <td style={{ padding: "4px 0px" }}> {name}</td>
                        <td>{bankName}</td> <td> {amount}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

BeneficiariesTable.propTypes = {
    beneficiaries: PropTypes.objectOf({
        name: PropTypes.string,
        bankName: PropTypes.string,
        amount: PropTypes.string,
    }).isRequired,
    columns: PropTypes.array,
};

function IntegrationIdPreviewer(props) {
    const { id } = props;
    const [, copyHandler] = useCopyToClipboard();

    const onCopy = () => {
        copyHandler(id);
        Notify(`Integration ID "${id}" copied to clipboard`, {
            position: "top-right",
        });
    };
    return (
        <Box>
            <Text>
                <span style={{ color: theme.PrimaryGreen }}>Integration created successfully.</span>
                <br />
                <br /> The Integration ID is returned below
            </Text>
            <Spacer space={8} />
            <Box display="flex" style={{ alignItems: "center" }}>
                <Box pad=" .8em 1em" style={{ border: `1px solid ${theme.SecondaryGrey}` }}>
                    <Text color={theme.PrimaryGrey} bold>
                        {id}
                    </Text>
                </Box>
                <Button spaceLeft plain onClick={onCopy} title="Copy Integration ID">
                    {iconCopy}
                </Button>
            </Box>
        </Box>
    );
}

IntegrationIdPreviewer.propTypes = {
    id: PropTypes.string,
};
