import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";

import { Grid, HookForm as Form } from "@/components";
import { useStepper } from "@/components/stepper/Stepper";
import { studyModeOptions } from "@/utils/uiOptions";

import { useMerchantConfiguration, useProductIntegration } from "../productIntegrationUtils";
import { PaymentMerchantFormWrapper } from "./productIntegrationStyles";

const { Input, Select } = Form;

const tabletGrid = "1fr / repeat(2, minmax(0,1fr))";

export function MerchantForm(props) {
    const { children, validation } = props;
    const { addToValues } = useProductIntegration();
    const { handleNext } = useStepper();
    const form = useForm({
        resolver: yupResolver(validation),
    });

    const { isReadyToSubmit, toggleIsReadyToSubmit, toggleWarn, warn } = useMerchantConfiguration();

    useEffect(() => {
        if (isReadyToSubmit) {
            const onSubmit = (formBody) => {
                addToValues(formBody);
                handleNext();
            };

            form.handleSubmit(onSubmit)();
        }

        isReadyToSubmit && toggleIsReadyToSubmit();
    }, [isReadyToSubmit]);

    useEffect(() => {
        // If form is not dirty,
        // and warn was previously true, it is toggled to false
        if (!form.formState.isDirty) {
            warn && toggleWarn();
            return;
        }
        toggleWarn(true);
    }, [form.formState.isDirty, warn]);

    return (
        <PaymentMerchantFormWrapper>
            <div className="form-wrapper">
                <Form form={form}>{children}</Form>
            </div>
        </PaymentMerchantFormWrapper>
    );
}

MerchantForm.propTypes = {
    children: PropTypes.node,
    validation: PropTypes.object.isRequired,
};

export function RemitaFormMarkup() {
    return (
        <Grid gap="3.8em">
            <Input name="parameters.REMITA_API_KEY" label="API Key" />
            <Input name="parameters.REMITA_MERCHANT_ID" label="Merchant ID" />
            <Input name="parameters.REMITA_SERVICE_TYPE_ID" label="Service Type ID" />
        </Grid>
    );
}

export function PaystackFormMarkup() {
    return (
        <Grid gap="3.8em">
            <Input name="parameters.SUB_ACCOUNT" label="Sub Account" />
            <Input name="parameters.SECRET_KEY" label="Secret Live Key" />
        </Grid>
    );
}

export function InterswitchMarkup() {
    const { warn, toggleWarn } = useMerchantConfiguration();

    useEffect(() => {
        warn && toggleWarn();
    }, [warn]);

    return (
        <Grid style={{ height: "100%", placeItems: "center", textAlign: "center" }}>
            <p>
                All good <br /> <br />
                There are no fields to set here.
            </p>
        </Grid>
    );
}

export function DreamLabsFormMarkup() {
    return (
        <Grid tablet={tabletGrid} gap="3.8em 1em">
            <Input name="parameters.ALL_ITEM_CODE_TOTAL" label="All Items Total Code" />
            <Input name="parameters.NON_TAXABLE" label="Non Taxable Item Code" />
            <Input name="parameters.NON_TAXABLE_TOTAL_CODE" label="Non Taxable Total Code" />
            <Input name="parameters.REMITA_SERVICE_TYPE_ID" label="Remita Service Type Id" />
            <Input name="parameters.SCHOOL_ID" label="School ID" />
            <Input name="parameters.SERVICE_CHARGE" label="Service Charge" />
            <Input name="parameters.TAXABLE" label="Taxable Item Code" />
            <Select name="parameters.STUDY_MODE" label="Study Modes" options={studyModeOptions} />
            <Input
                name="parameters.TAXABLE_AND_NON_TAXABLE_TOTAL_CODE"
                label="Taxable and Non Taxable Total Code"
            />
            <Input name="parameters.TAXABLE_TOTAL_CODE" label="Taxable Total Code" />
        </Grid>
    );
}

export function UnifiedPaymentsFormMarkup() {
    return (
        <Grid tablet={tabletGrid} gap="3.8em 2em">
            <Input name="parameters.GATEWAY_SCHOOL_ID" label="Gateway School ID" />
            <Input name="parameters.SECRET_KEY" label="Secret Key" />
            <Input name="parameters.PORTAL_ACCESS_KEY" label="Portal Access Key" />
            <Input name="parameters.REVENUE_CODE" label="Revenue Code" />
            <Input name="parameters.SERVICE_CHARGE_CODE" label="Service Charge Code" />
        </Grid>
    );
}
