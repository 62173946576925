import { breakpoints } from "@/utils/constants";
import styled from "styled-components";

const MainViewWrapper = styled.div`
    --margin-top: 85px;
    position: relative;
    padding: 1.5em 1em 0;
    margin-top: var(--margin-top);
    height: calc(100vh - var(--margin-top) - 5px);
    @media screen and ${breakpoints.TABLET} {
        padding: 3em 4em 0;
        width: 100%;
    }
    & > * {
        margin: auto;
    }
    @media screen and ${breakpoints.DESKTOP} {
        max-width: 100em;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const LogoContainer = styled.div`
    height: 3em;
    width: 3em;
    margin: 0 1em;

    & > img {
        width: 100%;
        height: auto;
    }
`;

export default MainViewWrapper;
