import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { Table as FlexibullTable } from "@flexisaf/flexibull2/build/table";

import { sortDirections } from "@/utils/constants";
import TitleCellWithSort from "./TitleCellWithSort";
import Actions from "./Actions";
import { StyledTable } from "./tableStyles";
import { Box } from "@flexisaf/flexibull2/build/layout";
import { Loader } from "@flexisaf/flexibull2/build/loader";

const getNewSortDirection = (oldSortDirection) => {
    if (oldSortDirection === sortDirections.ASC) return sortDirections.DESC;
    return sortDirections.ASC;
};

export default function Table(props) {
    const { columns, data, isUpdating, onSort, actions, ...restProps } = props;
    const [sortField, setSortField] = useState("");
    const [sortDirection, setSortDirection] = useState("");

    const handleSort = ({ field }) => {
        if (!onSort) return;
        if (field !== sortField) setSortField(field);
        const newSortDirection = getNewSortDirection(sortDirection);
        setSortDirection(newSortDirection);
        onSort({ field, sortDirection: newSortDirection });
    };

    const actualColumns = useMemo(() => {
        if (!Array.isArray(columns)) {
            return [];
        }

        let columnsEnhanced = columns.map((column) => {
            if (column.sort) {
                if (!column.key) {
                    throw new Error("'key' not provided. Cannot sort without 'key'");
                }
                const field = column.dataIndex || column.key;
                const title = (
                    <TitleCellWithSort
                        title={column.title}
                        field={field}
                        currentSortField={sortField}
                        currentSortDirection={sortDirection}
                        onSort={handleSort}
                    />
                );
                return { ...column, title };
            }
            return column;
        });

        if (actions) {
            if (!Array.isArray(actions)) {
                throw new Error("Expected actions to be an array of action items");
            }

            const actionColumn = {
                title: "Actions",
                render: (item) => <Actions actions={actions} item={item} />,
            };

            columnsEnhanced = [...columnsEnhanced, actionColumn];
        }
        return columnsEnhanced;
    });

    return (
        <Box style={{ position: "relative" }}>
            <FlexibullTable>
                {isUpdating && (
                    <Box
                        style={{
                            left: "50%",
                            top: "10%",
                            zIndex: "100",
                            padding: "1em",
                            transform: "translateX(50%)",
                        }}
                    >
                        <Loader size={36} />
                    </Box>
                )}
                <StyledTable
                    columns={actualColumns}
                    data={data}
                    isUpdating={isUpdating}
                    {...restProps}
                />
            </FlexibullTable>
        </Box>
    );
}

Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSort: PropTypes.func,
    actions: PropTypes.arrayOf(PropTypes.object),
    actionsAsContextMenu: PropTypes.bool,
    isLoading: PropTypes.bool,
    isUpdating: PropTypes.bool,
};
