import { createApi } from "@reduxjs/toolkit/query/react";

import config from "@/utils/config";
import { createBaseQuery } from "@/lib";
import { methods } from "@/utils/constants";
import cacher from "@/utils/rtkQueryCacheUtils";
import { Notify } from "@flexisaf/flexibull2/build/notify";

const tagType = "CONFIGURATION";

const configurationApi = createApi({
    baseQuery: createBaseQuery({ baseURL: `${config.apiURL}/config` }),
    tagTypes: [cacher.defaultTags, tagType],
    reducerPath: "configurationApi",
    endpoints: (builder) => ({
        productSetup: builder.mutation({
            queryFn: async (data, _queryApi, _extra, baseQuery) => {
                try {
                    const resp = await baseQuery({ url: `/setup`, method: methods.POST, data });
                    if (resp.error) throw resp.error;

                    Notify("Integration configured successfully", {
                        position: "top-right",
                        status: "success",
                    });
                    return { data: resp.data };
                } catch (error) {
                    return { error };
                }
            },
        }),
        updateIntegrationConfig: builder.mutation({
            query: (data) => ({
                url: "/school-integration-configs",
                data,
                method: methods.PUT,
            }),
            async onQueryStarted(_, { queryFulfilled: qf }) {
                qf.then(() => Notify("Integration Updated successfully")).catch((err) =>
                    Notify(err?.message, { status: "error" })
                );
            },
        }),
    }),
    refetchOnMountOrArgChange: true,
});

export const { useProductSetupMutation, useUpdateIntegrationConfigMutation } = configurationApi;
export default configurationApi;
