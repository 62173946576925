import { theme } from "@/style";

// Customer
export const CUSTOMER_ID_PREFIX = "SAF-SCH-";

// Storage
export const APP_PREFIX = "paylog";
export const APP_STORAGE_KEY = `storage:${APP_PREFIX}`;
// Network Error
export const NETWORK_ERR_CODE = 0;
export const NETWORK_ERR_MESSAGE = "Request Failed";

// QueryCache
export const UNAUTHORIZED = "UNAUTHORIZED";
export const UNKNOWN_ERROR = "UNKNOWN_ERROR";

// Methods
export const methods = {
    POST: "POST",
    GET: "GET",
    PUT: "PUT",
    PATCH: "PATCH",
    DELETE: "DELETE",
};

// Breakpoints
export const viewModes = {
    MOBILE: "mobile",
    TABLET: "tablet",
    DESKTOP: "desktop",
};

export const breakpoints = {
    MOBILE: "(max-width:480px)",
    TABLET: "(min-width:481px)",
    DESKTOP: "(min-width:769px)",
};

// Pagination
export const DEFAULT_LIMIT = 20;

// Sorting

export const sortDirections = {
    ASC: "asc",
    DESC: "desc",
};
// Execution Environment
export const PRODUCTION_ENV = "production";
export const DEVELOPMENT_ENV = "development";
export const STAGING_ENV = "staging";

// CSS Z Depths
export const zDepths = ["1000", "2000", "3000", "4000"];

// Messages
export const UNKNOWN_ERROR_MESSAGE = "Unknown error message";
export const NOTFOUND_ERROR_MESSAGE = "The requested resource was not found";
export const INTERNAL_SERVER_ERROR = "An internal server error occured";
export const UNAUTHORIZED_ERROR = "You are not authorized to perform this action";

// Payment Processors
export const platforms = {
    REMITA: "REMITA",
    INTERSWITCH: "INTERSWITCH",
    UNIFIED_PAYMENT: "UNIFIED_PAYMENTS",
    DREAMLABS: "DREAMLABS",
    PAYSTACK: "PAYSTACK",
    ESCHOOL: "E-SCHOOL",
};

// General Statuses
export const statuses = {
    PENDING: "PENDING",
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
};

// Contact Types
export const contactTypes = {
    EMAIL: "EMAIL",
    PHONE: "PHONE_NUMBER",
};

// Verification Types
export const verificationTypes = {
    LINK: "LINK",
};

export const beneficiaryTag = {
    CLIENT: "CLIENT",
    FLEXISAF: "FLEXISAF",
    OTHERS: "OTHERS",
};

// Roles

export const roles = {
    ADMIN: "ADMIN",
    CUSTOMER_SUPPORT: "CUSTOMER_SUPPORT",
    VIEWER: "VIEWER",
    CONFIG_MANAGER: "CONFIG_MANAGER",
};

export const roleColors = {
    [roles.ADMIN]: theme.PrimaryGreen,
    [roles.CUSTOMER_SUPPORT]: theme.SecondaryColor,
    [roles.VIEWER]: theme.PrimaryBlue,
    [roles.CONFIG_MANAGER]: theme.PrimaryYellow,
};

// Split Types
export const splitTypes = {
    PERCENTAGE: "PERCENTAGE",
    FIXED: "FIXED",
    RELATIVE: "RELATIVE",
};

export const bearerType = {
    PAYER: "PAYER",
    CLIENT: "CLIENT",
};
