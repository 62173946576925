import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { authApi } from "@/api";
import { APP_PREFIX } from "@/utils/constants";

const loginHandler = (state, { payload: auth }) => {
    state = auth;
    return state;
};

const initialState = {};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuth: loginHandler,
        updateAuthToken(store, { payload: authToken }) {
            store.authToken = authToken;
        },
        clearAuth() {
            storage.removeItem(APP_PREFIX);
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(authApi.endpoints.login.matchFulfilled, loginHandler);
    },
});

export const { setAuth, clearAuth, updateAuthToken } = authSlice.actions;
export const selectAuth = (state) => state.auth;

export default authSlice;
