const theme = {
    PrimaryColor: "#012351",

    PrimaryGrey: "#9D9DB7",
    SecondaryGrey: "#E3E8EF",
    TertiaryGrey: "#F7F9FC",

    PrimaryBackground: "#F9FAFB",
    PrimaryGreen: "#72E35D",
    PrimaryMint: "#95fd35",
    PrimaryRed: "#D81E5B",
    PrimaryYellow: "#FFCC00",
    PrimaryOrange: "#FF7B26",
    PrimaryBlue: "#5BB1F2",
    SecondaryColor: "#be7efc",
    TetiaryColor: "#ecdbfe",

    PrimaryFontSize: "12px",
    PrimaryFontColor: "#383A3F",

    PrimaryRadius: "3px",
    SecondaryRadius: "5px",

    PrimaryBorderColor: "#D8D8E3",
    PrimaryFade: "#F5F5F7",
    PrimaryInputOutline: "#9D9DB7",
    PrimaryInputDisabled: "#ECEEF0",
    PrimaryFadedBlue: "#dfe8f7",

    Duration: {
        short: 1000,
        mid: 3000,
        long: 5000,
    },

    Elevation: {
        Sm: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        Md: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        Lg: "box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    },
};

export default theme;
