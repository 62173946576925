import { Input as FlexiInput } from "@flexisaf/flexibull2/build/input";

import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { useController } from "react-hook-form";

import { Select } from "@/components";
import { PhoneInputWrapper } from "./PhoneInput.styled";

const NIGERIA_CALLING_CODE = "234";
const defaultRegex = /^\d*$/;

export default function PhoneInput(props) {
    const {
        name,
        label,
        placeholder,
        callingCodes = [],
        isLoading,
        disabled,
        defaultValue = NIGERIA_CALLING_CODE,
        rules = {},
        phoneNumberRegex = defaultRegex,
        ...restProps
    } = props;
    const { field, fieldState } = useController({ name, defaultValue, rules });

    const [phoneNumber, setPhoneNumber] = useState("");
    const [countryCode, setCountryCode] = useState("");

    const handleCallingCodeChange = (val) => {
        setCountryCode(val);
        field.onChange(`${val}${phoneNumber}`);
    };

    const handlePhoneNumberChange = (event) => {
        const { value } = event.target;
        if (phoneNumberRegex.test(value)) {
            setPhoneNumber(value);
            field.onChange(`${countryCode}${value}`);
        }
    };

    const isDisabled = useMemo(
        () => disabled || callingCodes?.length < 1,
        [disabled, callingCodes]
    );

    return (
        <PhoneInputWrapper hasError={true} isDisabled={isDisabled}>
            <Select
                value={countryCode}
                onChange={handleCallingCodeChange}
                options={callingCodes}
                isLoading={isLoading}
                disabled={isDisabled}
                label={label}
            />
            <FlexiInput
                name={name}
                type="tel"
                disabled={isDisabled}
                placeholder={placeholder}
                onChange={handlePhoneNumberChange}
                value={phoneNumber}
                {...restProps}
            />
            {fieldState.error?.message && <div className="error"> {fieldState.error?.message}</div>}
        </PhoneInputWrapper>
    );
}

PhoneInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    rules: PropTypes.object,
    phoneNumberRegex: PropTypes.instanceOf(RegExp),
    callingCodes: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
};
