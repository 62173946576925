import PropTypes from "prop-types";
import { useMemo } from "react";
import { useController } from "react-hook-form";

import { Input as FlexiInput } from "@flexisaf/flexibull2/build/input";

export default function Input(props) {
    const {
        name,
        label,
        placeholder,
        type = "text",
        defaultValue = "",
        rules = {},
        ...restProps
    } = props;
    const { field, fieldState } = useController({ name, defaultValue, rules });

    const allRestProps = useMemo(() => {
        if (type === "currency") {
            return { ...restProps, onValueChange: field.onChange };
        }
        return { ...restProps, onChange: field.onChange };
    }, [restProps, type]);

    return (
        <FlexiInput
            name={name}
            label={label}
            type={type}
            placeholder={placeholder}
            value={field.value}
            error={fieldState.error?.message}
            {...allRestProps}
        />
    );
}

Input.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rules: PropTypes.object,
};
