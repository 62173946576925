import { breakpoints } from "@/utils/constants";
import styled, { css } from "styled-components";

export const Grid = styled.div`
    display: grid;
    grid-template: ${(p) => p.default};
    grid-gap: ${(p) => p.gap};
    width: ${(p) => p.width};
    margin: ${(p) => p.margin};
    ${(p) =>
        p.tablet &&
        css`
            @media ${breakpoints.TABLET} {
                grid-template: ${(p) => p.tablet};
            }
        `}
    ${(p) =>
        p.desktop &&
        css`
            @media ${breakpoints.DESKTOP} {
                grid-template: ${(p) => p.desktop};
            }
        `};
`;

Grid.defaultProps = {
    default: "none",
    className: "paylog-grid",
    gap: "0px",
    margin: "none",
    width: "auto",
};
