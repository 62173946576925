import sub from "date-fns/sub";

const today = new Date();

const getDaysPast = () => today.getDate();
const getThisMonth = () => sub(today, { days: getDaysPast() - 1 });
const getLastMonth = () => sub(today, { days: getDaysPast() - 1, months: 1 });

export const CUSTOM_DATE_VALUE = "CUSTOM_DATE";
export const customDateOption = { label: "Custom Date", value: CUSTOM_DATE_VALUE };

export const defaultDateOptions = [
    {
        label: "Today",
        value: today,
    },
    { label: "Yesterday", value: sub(today, { days: 1 }) },
    { label: "A Week Ago", value: sub(today, { weeks: 1 }) },
    { label: "This Month", value: getThisMonth() },
    { label: "Last Month", value: getLastMonth() },
    customDateOption,
];
