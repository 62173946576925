import formatDate from "date-fns/format";
import PropTypes from "prop-types";
import { useMemo, useRef, useState } from "react";
import { useClickAway, useToggle } from "react-use";
import ty from "typy";
import { Select } from "@flexisaf/flexibull2/build/select";

import { DateRangeSelector } from "@/components";
import { customDateOption, CUSTOM_DATE_VALUE, defaultDateOptions } from "./filterUtils";

const prettyPrintRange = ({ startDate, endDate }) => {
    if (!ty(startDate).isDate || !ty(endDate).isDate)
        throw new Error("Range not valid date objects");
    const prettyDateRange = `${formatDate(startDate, "dd/MM/yy")} - ${formatDate(
        endDate,
        "dd/MM/yy"
    )} `;
    return { label: prettyDateRange, value: { startDate, endDate } };
};

export default function DateFilter(props) {
    const { onChange, dateOptions = [], ...restProps } = props;
    const [selectedDate, setSelectedDate] = useState();
    const [isDateRangeSelectorOn, toggleDateRangeSelector] = useToggle();
    const dateRangeSelectorRef = useRef();

    const handleChange = (targetDate) => {
        if (targetDate.value === CUSTOM_DATE_VALUE) {
            toggleDateRangeSelector();
            return;
        }
        setSelectedDate(targetDate);
        onChange(targetDate.value);
    };

    const handleCustomDate = (customDate) => {
        toggleDateRangeSelector();
        setSelectedDate({ ...customDate, isCustom: true });
        onChange(customDate);
    };

    const options = useMemo(
        () => (dateOptions.length ? [...dateOptions, customDateOption] : defaultDateOptions),
        [dateOptions]
    );

    useClickAway(dateRangeSelectorRef, toggleDateRangeSelector);

    return (
        <>
            <Select
                value={selectedDate?.isCustom ? prettyPrintRange(selectedDate) : selectedDate}
                options={options}
                onChange={handleChange}
                {...restProps}
            />
            <DateRangeSelector
                isOpen={isDateRangeSelectorOn}
                onClose={() => toggleDateRangeSelector(false)}
                ref={dateRangeSelectorRef}
                onChange={handleCustomDate}
            />
        </>
    );
}

DateFilter.propTypes = {
    onChange: PropTypes.func,
    dateOptions: PropTypes.arrayOf(PropTypes.object),
};
