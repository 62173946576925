import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { theme } from "@/style";

export const Link = styled(RouterLink)`
    color: ${theme.PrimaryGrey};
    &:hover {
        text-decoration: underline;
    }
`;
