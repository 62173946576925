import { createApi } from "@reduxjs/toolkit/query/react";

import config from "@/utils/config";
import { createBaseQuery } from "@/lib";
import cacher from "@/utils/rtkQueryCacheUtils";

const roleBaseUrl = `${config.umsURL}/products/${config.umsProductId}/organisations/${config.umsOrganisation}/roles`;

const roleApi = createApi({
    baseQuery: createBaseQuery({ baseURL: roleBaseUrl }),
    tagTypes: [...cacher.defaultTags, "ROLE"],
    reducerPath: "roleApi",
    endpoints: (builder) => ({
        getRoles: builder.query({
            query: () => ({
                url: "",
            }),
        }),
    }),
});

export const { useGetRolesQuery } = roleApi;

export default roleApi;
