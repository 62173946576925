import PropTypes from "prop-types";
import { Box } from "@flexisaf/flexibull2/build/layout";

export default function LoaderLayout(props) {
    const { children: loader } = props;
    return (
        <Box
            display="flex"
            style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
            }}
        >
            {loader}
        </Box>
    );
}

LoaderLayout.propTypes = {
    children: PropTypes.node,
};
