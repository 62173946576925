import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { theme } from "@/style";

const CardLink = styled(NavLink)`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: black;
    max-width: 10em;
    padding: 0.375em 0.625em;

    border: 1px solid ${theme.PrimaryGrey};
    border-radius: 6px;

    & .label {
        margin-right: 0.25em;
    }
    & .icon {
        width: 1.5em;
        height: 1.5em;
    }
`;

export default CardLink;
