import { createApi } from "@reduxjs/toolkit/query/react";
import { minutesToMilliseconds } from "date-fns";

import config from "@/utils/config";
import { createBaseQuery } from "@/lib";
import { beneficiaryTag, methods } from "@/utils/constants";
import cacher from "@/utils/rtkQueryCacheUtils";
import { Notify } from "@flexisaf/flexibull2/build/notify";
import { splitTypes } from "@/utils/constants";
import { formatBeneficiaryAmount, getErrorOrFallbackMsg, hasItems } from "@/utils/help";

const tagType = "PRODUCT_INTEGRATION";

const productIntegrationApi = createApi({
    baseQuery: createBaseQuery({ baseURL: `${config.apiURL}/integrations` }),
    tagTypes: [cacher.defaultTags, tagType],
    reducerPath: "productIntegrationApi",
    keepUnusedDataFor: minutesToMilliseconds(2),
    endpoints: (builder) => ({
        getProductIntegrations: builder.query({
            query: (data) => ({
                url: "",
                data,
                method: methods.GET,
            }),
            keepUnusedDataFor: minutesToMilliseconds(30),
            transformResponse: (data) => data.map(transformProductIntegration),
            providesTags: [tagType],
        }),
        getProductIntegration: builder.query({
            query: (schoolConfigId) => ({
                url: "",
                method: methods.GET,
                data: { "school-config-ids": schoolConfigId },
            }),
            transformResponse: (data = []) => data.map(transformProductIntegration)[0],
            keepUnusedDataFor: minutesToMilliseconds(2),
        }),
        updateProductIntegration: builder.mutation({
            query: (data) => ({
                url: "/setup",
                data,
                method: methods.PUT,
            }),
            async onQueryStarted(_, { queryFulfilled: qf }) {
                qf.then(() => Notify("Integration Updated successfully")).catch(({ error }) =>
                    Notify(getErrorOrFallbackMsg(error), { status: "error" })
                );
            },
            invalidatesTags: [tagType],
        }),
        createProductIntegration: builder.mutation({
            queryFn: async (data, _queryApi, _extra, baseQuery) => {
                try {
                    const resp = await baseQuery({ url: `/setup`, method: methods.POST, data });
                    if (resp.error) throw resp.error;

                    Notify("Integration configured successfully", { position: "top-right" });
                    return { data: resp.data };
                } catch (error) {
                    return { error };
                }
            },
            invalidatesTags: [tagType],
        }),
        deleteProductIntegration: builder.mutation({
            query: (id) => ({
                url: `/integrations/${id}`,
                method: methods.DELETE,
            }),
        }),
    }),
});

const transformProductIntegration = (integration) => {
    if (typeof integration !== "object") return {};
    const { parameters: rawParameters, splitDetails, platform: rawPlatform } = integration;

    const parameters = rawParameters?.reduce((acc, parameter) => {
        acc[parameter.configKey] = parameter.value;
        return acc;
    }, {});

    // EDGE CASE: If there are parameters and we have the study mode parameter,
    // we would like to transform it to uppercase
    if (parameters?.STUDY_MODE) {
        parameters.STUDY_MODE = parameters.STUDY_MODE?.toUpperCase() || parameters.STUDY_MODE;
    }

    // EDGE CASE: We want to convert USE_SPLIT string 'true' or 'false' values to boolean.
    if (parameters?.USE_SPLIT) {
        parameters.USE_SPLIT = parameters.USE_SPLIT === "true";
    }

    const platform = { ...rawPlatform, value: rawPlatform.id, label: rawPlatform.description };
    let beneficiaries = [];
    let flexisafCut = 0;
    if (splitDetails) {
        beneficiaries = getBeneficiaries(splitDetails);
        flexisafCut = getFlexisafCut(beneficiaries, splitDetails.type);
    }
    return {
        school: integration.school.name,
        schoolId: integration.school.id,
        flexisafCut,
        platform,
        schoolConfigId: integration.schoolConfigId,
        product: integration.product.description,
        isSplit: hasItems(beneficiaries),
        splitType: splitDetails?.type,
        parameters,
        beneficiaries,
    };
};

const getFlexisafCut = (beneficiaries, splitType) => {
    if (!beneficiaries) return 0;
    let flexisafCut = 0;
    const flexisafBeneficiary = beneficiaries.find(({ tag }) => tag === beneficiaryTag.FLEXISAF);

    if (!flexisafBeneficiary) return "N/A";

    flexisafCut = flexisafBeneficiary.amount;

    return formatBeneficiaryAmount(flexisafCut, splitType);
};

const getBeneficiaries = ({ beneficiaries, type: splitType }) => {
    if (!beneficiaries || !splitType) return [];
    const sortedBeneficiaries = sortBeneficiaries(beneficiaries, splitType);

    return sortedBeneficiaries
        ?.filter(Boolean)
        .map(
            ({
                deductFrom,
                accountNum: accountNumber,
                itemName: name,
                percentage,
                amount,
                ...others
            }) => ({
                ...others,
                accountNumber,
                name,
                amount: splitType === splitTypes.PERCENTAGE ? percentage : amount,
                deductChargeOn: deductFrom ? true : false,
            })
        );
};

const sortBeneficiaries = (beneficiaries, typeOfSplit) => {
    if (typeOfSplit === splitTypes.RELATIVE) {
        const benfWithBal = beneficiaries.find((bf) => bf.amount === 0);
        const restBeneficiaries = beneficiaries.filter((bf) => bf.amount !== 0);
        return [benfWithBal, ...restBeneficiaries];
    }
    return beneficiaries;
};

export const {
    useCreateProductIntegrationMutation,
    useGetProductIntegrationQuery,
    useGetProductIntegrationsQuery,
    useUpdateProductIntegrationMutation,
    useDeleteProductIntegrationMutation,
} = productIntegrationApi;
export default productIntegrationApi;
