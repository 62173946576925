import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import ty from "typy";

import config from "@/utils/config";
import { methods } from "@/utils/constants";
import cacher from "@/utils/rtkQueryCacheUtils";

const countryApi = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: config.countryApiURL }),
    tagTypes: [...cacher.defaultTags, "AUTH"],
    reducerPath: "countryApi",
    endpoints: (builder) => ({
        countries: builder.query({
            query: () => ({ url: "/all", method: methods.GET }),
        }),
        countryCodes: builder.query({
            query: () => ({
                url: "/all",
                method: methods.GET,
            }),
            transformResponse: getCallingCodes,
        }),
    }),
});

function getCallingCodes(countries = []) {
    return countries.map(({ alpha3Code, callingCodes }) => ({
        label: ty(callingCodes).isArray && `${alpha3Code}(+${callingCodes[0]})`,
        value: ty(callingCodes).isArray && `+${callingCodes[0]}`,
    }));
}

export const { useCountriesQuery, useCountryCodesQuery } = countryApi;
export default countryApi;
