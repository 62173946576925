import styled from "styled-components";

import { theme, styleUtils } from "@/style";
import { breakpoints } from "@/utils/constants";

export const ProductIntegrationWrapper = styled.div`
    margin-top: -2em;
    width: 100%;
    @media ${breakpoints.TABLET} {
        & .flexi-wizard {
            background: linear-gradient(to right, #f3f4f6, #f3f4f6 324px, white 324px) !important;
            padding-bottom: 0px;
            box-shadow: none;
            transition: 0.4s ease-in-out;
        }
    }
    @media (max-width: 800px) {
        & .flexi-wizard {
            background: white !important;
        }
    }
`;

export const ContentBaseWrapper = styled.div`
    margin-top: 3em;

    min-height: 75vh;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    & .content {
        padding: 0.5em;
        width: 100%;

        & .title {
            color: ${theme.PrimaryGrey};
            margin-bottom: 0 0 0 3em;
        }

        /* Overrides for flexibull Input */
        & .flexi-input-affix-wrapper {
            min-width: 12em;
            @media ${breakpoints.TABLET} {
                min-width: auto;
            }
        }

        /* Overrides for flexibull Select */
        & .flexi-select {
            width: auto;
            & .flexi__control {
                min-width: auto;
            }
        }
    }
`;

export const PaymentMerchantFormWrapper = styled.div`
    position: relative;
    padding: 2em 1.3em;
    @media ${breakpoints.TABLET} {
        padding: 2em 2.3em;
    }

    & .logo {
        position: absolute;
        top: 2em;
        right: 2.75em;
        img {
            width: 100%;
            object-fit: cover;
        }
    }
    & .form-wrapper {
        margin-top: 4em;

        & .flexi-select {
            width: auto;
            & .flexi__control {
                min-width: auto;
            }
        }
    }

    & .flexi-input-affix-wrapper {
        min-width: auto;
    }
`;

export const TransactionChargeWrapper = styled.div`
    padding-top: 3em;
`;

export const BeneficiaryWrapper = styled.div`
    padding-bottom: 2em;
    border-bottom: 1px solid ${theme.SecondaryGrey};
    margin-bottom: 2em;
    width: 90%;

    & .fields {
        align-items: start;
        position: relative;
    }
`;

export const DeleteButton = styled.button`
    ${styleUtils.resetButton()}

    display: flex;
    align-items: center;
    opacity: 0.6;

    color: ${theme.PrimaryRed};
    & svg {
        stroke: ${theme.PrimaryRed};
        width: 1.4em;
        height: 1.4em;
    }

    &:hover {
        opacity: 1;
    }

    @media ${breakpoints.TABLET} {
        position: absolute;
        right: -6%;
        top: 10px;
        & .text {
            display: none;
        }
    }
`;
