import PropTypes from "prop-types";

import { Text } from "@flexisaf/flexibull2/build/typo";

import { CardLink } from "@/components";
import { IconRight } from "@/components/icons";

import { NavCardWrapper } from "./NavCard.styled";
import { theme } from "@/style";

export default function NavCard(props) {
    const { title, description, customCardLink, linkPath, linkText, ...restProps } = props;
    return (
        <NavCardWrapper {...restProps}>
            <div className="card-section">
                <Text block color={theme.PrimaryColor} bold size="1em" className="title">
                    {title}
                </Text>
                <Text block className="description">
                    {" "}
                    {description}{" "}
                </Text>
                {customCardLink ? (
                    customCardLink
                ) : (
                    <CardLink to={linkPath}>
                        <span className="label">{linkText}</span>
                        <IconRight className="icon" />
                    </CardLink>
                )}
            </div>
        </NavCardWrapper>
    );
}

NavCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    customCardLink: PropTypes.node,
    linkPath: PropTypes.string,
    linkText: PropTypes.string,
};
