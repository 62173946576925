import { platforms } from "@/utils/constants";
import * as yup from "yup";
import { splitTypes } from "@/utils/constants";
import { getTotalPercentage } from "./productIntegrationUtils";

export const getStringSchema = (label) => yup.string().label(label);
export const asParameterSchema = (validationObj) => yup.object({ parameters: validationObj });

export const customerDetailsValidation = yup.object({
    description: getStringSchema("Description").required(),
    schoolId: getStringSchema("School").required(),
});

export const remitaFormValidation = yup.object({
    REMITA_API_KEY: getStringSchema("API Key").required(),
    REMITA_MERCHANT_ID: getStringSchema("Merchant ID").required(),
    REMITA_SERVICE_TYPE_ID: getStringSchema("Service Type ID").required(),
});

export const dreamlabsFormValidation = yup.object({
    ALL_ITEM_CODE_TOTAL: getStringSchema("All Items Total Code").required(),
    NON_TAXABLE: getStringSchema("Non Taxable Item Code").required(),
    NON_TAXABLE_TOTAL_CODE: getStringSchema("Non Taxable Total Code").required(),
    REMITA_SERVICE_TYPE_ID: getStringSchema("Remita Service Type ID").required(),
    SCHOOL_ID: getStringSchema("School ID").required(),
    SERVICE_CHARGE: getStringSchema("Service Charge").required(),
    STUDY_MODE: getStringSchema("Study Mode").required(),
    TAXABLE: getStringSchema("Taxable Item Code").required(),
    TAXABLE_AND_NON_TAXABLE_TOTAL_CODE: getStringSchema(
        "Taxable and Non Taxable Total Code"
    ).required(),
    TAXABLE_TOTAL_CODE: getStringSchema("Taxable Total Code").required(),
});

export const paystackFormValidation = yup.object({
    SUB_ACCOUNT: getStringSchema("Sub Account").required(),
    SECRET_KEY: getStringSchema("Secret Live Key").required(),
});

export const unifiedPaymentsFormValidation = yup.object({
    GATEWAY_SCHOOL_ID: getStringSchema("Gateway School ID").required(),
    SECRET_KEY: getStringSchema("Secret Key").required(),
    PORTAL_ACCESS_KEY: getStringSchema("Portal Access Key").required(),
    REVENUE_CODE: getStringSchema("Revenue Code").required(),
    SERVICE_CHARGE_CODE: getStringSchema("Service Charge Code").required(),
});

const beneficiariesSchema = yup.array().of(
    yup.object().shape({
        name: getStringSchema("Name").required(),
        accountNumber: getStringSchema("Account Number").required(),
        bank: yup.object().label("Bank").nullable().required(),
        amount: getStringSchema("Share").required(),
        tag: getStringSchema("Tag").required(),
        deductChargeOn: getStringSchema("Deduct Charge flag").required(),
        bearer: getStringSchema("Beaere").required(),
    })
);

export const splitSchemas = {
    isSplit: yup.boolean(),
    splitType: yup
        .string()
        .nullable()
        .when("isSplit", {
            is: true,
            then: getStringSchema("Split Type").required(),
        }),
};

export const transactionChargeValidation = yup.object({
    ...splitSchemas,
    beneficiaries: yup.array().when("isSplit", {
        is: true,
        then: beneficiariesSchema.required(),
    }),
});

export const editProductIntegrationValidation = yup.object({
    ...transactionChargeValidation.fields,
    platform: yup.object(),
    parameters: yup.object().when("platform", (platform, schema) => {
        if (!platform) return schema;
        if (platform.value === platforms.REMITA) return remitaFormValidation;
        if (platform.value === platforms.DREAMLABS) return dreamlabsFormValidation;
        if (platform.value === platforms.PAYSTACK) return paystackFormValidation;
    }),
});

export const transactionChargeErrors = {
    noBeneficiaries: { type: "NO_BENEFICIARIES", message: "No Beneficiaries has been added" },
    notEnoughRelativeBeneficiaries: {
        type: "NOT_ENOUGH_RELATIVE_BENEFICIARIES",
        message: "Relative split payment involves not less than 2 beneficiaries",
    },
    failFetchBanks: { type: "FAILED_FETCHED_BANKS", message: "Unable to retrieve banks" },
    wrongPercentage: {
        type: "WRONG_PERCENTAGE",
        message: "Total Percentage cannot be greater than or less than 100",
    },
};

export const onSubmitValidation = (values) => {
    const { isSplit, splitType, beneficiaries } = values;
    const totalPercentage = getTotalPercentage(beneficiaries, splitType);

    if (isSplit) {
        if (beneficiaries.length === 0) {
            return transactionChargeErrors.noBeneficiaries;
        }

        if (splitType === splitTypes.PERCENTAGE && totalPercentage !== 100) {
            return transactionChargeErrors.wrongPercentage;
        }
    }
};
