import formatDate from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import { noop } from "lodash";
import PropTypes from "prop-types";
import ty from "typy";
import { useState, useCallback } from "react";
import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { Box } from "@flexisaf/flexibull2/build/layout";
import { Button } from "@flexisaf/flexibull2/build/button";
import { Modal, ModalBody } from "@flexisaf/flexibull2/build/modal";
import { Text } from "@flexisaf/flexibull2/build/typo";

import { theme } from "@/style";
import TimeRangePicker, { defaultTimeRange } from "./TimeRangePicker";

const today = new Date();
const keys = { SELECTION: "selection" };

const getDateWithTime = (date, time) => {
    let dateStr = formatDate(date, "yyyy-MM-dd");
    const dateObj = new Date(`${dateStr}T${time}`);
    return dateObj;
};

const getTimeinHHmm = (date) => formatDate(date, "HH:mm");

const getTimeRange = ({ startDate, endDate }) => ({
    from: getTimeinHHmm(startDate),
    to: getTimeinHHmm(endDate),
});

const validateDates = (startDate, endDate) => {
    if (startDate > endDate) {
        return isSameDay(startDate, endDate)
            ? "Start time cannot be greater than end time in range"
            : "Start date cannot be greater than end date in range";
    }
    return null;
};

export default function DateRangeSelector(props) {
    const { onChange, onClose, isOpen, inline, id = "date-range-selector", ...restProps } = props;
    const [dateRange, setDateRange] = useState(() => [
        { startDate: today, endDate: today, key: keys.SELECTION },
    ]);
    const [timeRange, setTimeRange] = useState(defaultTimeRange);
    const [error, setError] = useState();

    const handleChange = useCallback((dateRangeValue) => {
        setDateRange([dateRangeValue.selection]);
        setTimeRange(getTimeRange(dateRangeValue.selection));
    }, []);

    const handleSave = useCallback(() => {
        !error && onChange(dateRange[0]);
    }, [dateRange]);

    const handleTimeRangeChange = useCallback(
        (timeRange) => {
            setError(null);
            const { from, to } = timeRange;
            const startDateWithTime = getDateWithTime(dateRange[0].startDate, from);
            const endDateWithTime = getDateWithTime(dateRange[0].endDate, to);
            const validationError = validateDates(startDateWithTime, endDateWithTime);

            if (validationError) {
                setError(validationError);
                return;
            }

            setDateRange(([prevDateRange]) => {
                const newDateRange = [
                    { ...prevDateRange, startDate: startDateWithTime, endDate: endDateWithTime },
                ];
                inline && onChange(newDateRange);
                return newDateRange;
            });
        },
        [dateRange]
    );

    const dateRangePickerRender = useCallback(
        () => (
            <Box>
                <DateRangePicker
                    {...restProps}
                    ranges={dateRange}
                    onChange={handleChange}
                    showDateDisplay={true}
                />
                <Box
                    display="flex"
                    style={{
                        justifyContent: "center",
                        margin: "2.5em 0 1em 0",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <TimeRangePicker timeRange={timeRange} onChange={handleTimeRangeChange} />

                    <Text block color={theme.PrimaryRed}>
                        {error}
                    </Text>
                </Box>
            </Box>
        ),
        [error, handleTimeRangeChange, handleChange, dateRange]
    );

    if (inline) return dateRangePickerRender();

    return (
        <Modal
            outerclick
            onClose={() => (ty(onClose).isFunction ? onClose() : noop())}
            id={id}
            open={isOpen}
        >
            <ModalBody width="max-content">
                <Box pad="2em">
                    {dateRangePickerRender()}
                    <Box
                        display="flex"
                        style={{ justifyContent: "flex-end", alignItems: "center" }}
                        pad="1em"
                    >
                        <Button onClick={handleSave}> Save </Button>
                    </Box>
                </Box>
            </ModalBody>
        </Modal>
    );
}

DateRangeSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    inline: PropTypes.bool,
    id: PropTypes.string,
    ref: PropTypes.object,
};
