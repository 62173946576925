import PropTypes from "prop-types";
import { useState, useCallback, useEffect } from "react";
import ty from "typy";

import { Input } from "@flexisaf/flexibull2/build/input";
import { Box } from "@flexisaf/flexibull2/build/layout";

export const defaultTimeRange = { from: "00:00", to: "00:00" };
export default function TimeRangePicker(props = defaultTimeRange) {
    const { onChange, timeRange: tRange } = props;
    const [timeRange, setTimeRange] = useState(tRange);

    useEffect(() => {
        setTimeRange(tRange);
    }, [tRange]);

    const handleChange = useCallback(
        (event) => {
            const { name, value } = event.target;
            setTimeRange((prevTimeRange) => ({ ...prevTimeRange, [name]: value }));
        },
        [timeRange]
    );

    useEffect(() => {
        ty(onChange).isFunction && onChange(timeRange);
    }, [timeRange]);

    return (
        <Box display="flex" style={{ alignItems: "flex-start" }} margin="0 0 .5em">
            <Input
                name="from"
                value={timeRange.from}
                label="From"
                type="time"
                onChange={handleChange}
            />
            <Input name="to" value={timeRange.to} label="To" type="time" onChange={handleChange} />
        </Box>
    );
}

TimeRangePicker.propTypes = {
    onChange: PropTypes.func,
    timeRange: PropTypes.object,
};
