/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "@/reportWebVitals";
import App from "@/routes/AppRouter";
import "@/assets/iconsax/css/iconsax.css";
import { isEnv } from "./utils/help";
import { DEVELOPMENT_ENV } from "./utils/constants";

// if (isEnv(DEVELOPMENT_ENV)) {
//     // eslint-disable-next-line
//     const { worker } = require("./mock/browser");
//     worker.start();
// }

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
