import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { useNetworkState, useToggle } from "react-use";
import { NetworkIndicatorWrapper } from "./NetworkIndicator.styled";

const DEFAULT_TIMEOUT = 2000;
export default function NetworkIndicator(props) {
    const { timeout = DEFAULT_TIMEOUT } = props;
    const [isShow, toggleShow] = useToggle();
    const { online: isOnline } = useNetworkState();
    const hideTimeoutRef = useRef();

    useEffect(() => {
        if (!isOnline && !isShow) {
            toggleShow();
            return;
        }

        if (isOnline && isShow) {
            hideTimeoutRef.current = setTimeout(toggleShow, timeout);
        }

        return () => hideTimeoutRef.curret && clearTimeout(hideTimeoutRef.current);
    }, [isOnline]);

    return !isShow ? null : (
        <NetworkIndicatorWrapper isOnline={isOnline}>
            {isOnline ? "Connective Active" : "No Connection"}
        </NetworkIndicatorWrapper>
    );
}

NetworkIndicator.propTypes = {
    timeout: PropTypes.number,
};
