import styled, { css } from "styled-components";

import { theme } from "@/style";
import { breakpoints } from "@/utils/constants";

export const OverViewCardWrapper = styled.div`
    line-height: 2em;
    border-radius: 0px;
    display: flex;
    padding: 0 2em;
    flex-flow: column wrap;
    justify-content: center;

    & .title {
        color: ${theme.PrimaryGrey};
        margin-bottom: 1.25em;
    }
    & .value {
        font-size: ${(p) => (p.highlighted ? "4em" : "2.16em")};
        margin-bottom: 0.6em;
        text-overflow: wrap;
        font-weight: 700;
    }

    & .link {
        font-size: 0.83em;
    }

    & .description {
        color: ${theme.PrimaryGrey};
        font-size: 1.3em;
    }

    & .icon {
        height: 14px;
        width: 14px;
        margin-left: 0.3em;
    }
    & .icon__link {
        transform: rotateZ(325deg);
    }
`;

export const OverViewWrapper = styled.section`
    display: grid;
    grid-row-gap: 1em;
    border: ${(p) => (p.bordered ? `1px solid ${theme.PrimaryBorderColor}` : "none")};
    box-sizing: border-box;
    border-radius: 4px;
    overflow-x: scroll;

    ${(p) =>
        p.itemsDashed &&
        css`
            padding: 1.66em 0;
            & > *:not(:last-child) {
                border-right: 1px dashed ${theme.PrimaryBorderColor};
            }
            @media ${breakpoints.MOBILE} {
                & > *:not(:last-child) {
                    border-bottom: 1px dashed ${theme.PrimaryBorderColor};
                    border-right: none;
                }
            }
        `}

    ${(p) =>
        p.background &&
        css`
            background: ${p.background};
        `};

    ${(p) =>
        p.margin &&
        css`
            margin: ${p.margin};
        `}

    @media ${breakpoints.MOBILE} {
        grid-auto-flow: row;
    }

    @media ${breakpoints.TABLET} {
        grid-auto-flow: column;
    }
`;

export const OverViewTitleWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5em;
    width: 100%;

    @media ${breakpoints.MOBILE} {
        flex-flow: column;
        & .title,
        .icon {
            margin-bottom: 2em;
        }
    }

    & .title {
        font-size: 1.5em;
        font-weight: bold;
    }

    & .icon {
        margin-left: 0.41em;
        height: 1.3em;
        width: 1.3em;
    }
`;
