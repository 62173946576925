export const appPrivileges = {
    createIntegrations: "integrations:c",
    readIntegrations: "integrations:r",
    updateIntegrations: "integrations:u",
    deleteIntegrations: "integrations:d",

    createTransactions: "transactions:c",
    readTransactions: "transactions:r",
    updateTransactions: "transactions:u",
    deleteTransactions: "transactions:d",

    createUsers: "users:c",
    readUsers: "users:r",
    updateUsers: "users:u",
    deleteUsers: "users:d",

    createCustomers: "customers:c",
    readCustomers: "customers:r",
    updateCustomers: "customers:u",
    deleteCustomers: "customers:d",

    createRoles: "roles:c",
    readRoles: "roles:r",
    updateRoles: "roles:u",
    deleteRoles: "roles:d",
};
