import merchantLogo from "@/assets/icons/iconsax/3dcube.svg";
import { Stepper, Tabs } from "@/components";
import { useStepper } from "@/components/stepper/Stepper";
import { platforms } from "@/utils/constants";

import {
    MerchantForm,
    RemitaFormMarkup,
    PaystackFormMarkup,
    InterswitchMarkup,
    DreamLabsFormMarkup,
} from "./MerchantForms";

import { useProductIntegration, MerchantConfigurationContext } from "../productIntegrationUtils";
import { ContentBaseWrapper } from "./productIntegrationStyles";
import {
    remitaFormValidation,
    paystackFormValidation,
    dreamlabsFormValidation,
    asParameterSchema,
} from "../productIntegrationValidation";
import { useToggle } from "react-use";

export default function MerchantConfiguration() {
    const { values, addToValues } = useProductIntegration();
    const { handleNext } = useStepper();
    const [isReadyToSubmit, toggleIsReadyToSubmit] = useToggle();
    const [warn, toggleWarn] = useToggle();
    const handleTabChange = ({ indexKey }) => {
        addToValues({ platform: indexKey });
    };

    const handleNextClick = () => {
        if (values.platform === platforms.INTERSWITCH) {
            handleNext();
            return;
        }
        toggleIsReadyToSubmit();
    };

    return (
        <MerchantConfigurationContext.Provider
            value={{ isReadyToSubmit, toggleIsReadyToSubmit, warn, toggleWarn }}
        >
            <ContentBaseWrapper>
                <div className="content">
                    <Tabs onTabChange={handleTabChange} warnOnTabSwitch={warn}>
                        <Tabs.Panel index={0} title="Remita" indexKey={platforms.REMITA}>
                            <MerchantForm
                                logoUrl={merchantLogo}
                                validation={asParameterSchema(remitaFormValidation)}
                            >
                                <RemitaFormMarkup />
                            </MerchantForm>
                        </Tabs.Panel>
                        <Tabs.Panel index={1} title="Paystack" indexKey={platforms.PAYSTACK}>
                            <MerchantForm validation={asParameterSchema(paystackFormValidation)}>
                                <PaystackFormMarkup />
                            </MerchantForm>
                        </Tabs.Panel>
                        <Tabs.Panel index={2} title="Interswitch" indexKey={platforms.INTERSWITCH}>
                            <InterswitchMarkup />
                        </Tabs.Panel>

                        <Tabs.Panel index={3} title="Dreamlabs" indexKey={platforms.DREAMLABS}>
                            <MerchantForm validation={asParameterSchema(dreamlabsFormValidation)}>
                                <DreamLabsFormMarkup />
                            </MerchantForm>
                        </Tabs.Panel>
                    </Tabs>
                </div>
                <Stepper.StepButtons onNext={handleNextClick} />
            </ContentBaseWrapper>
        </MerchantConfigurationContext.Provider>
    );
}
