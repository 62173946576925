import { darken } from "polished";
import { useKey } from "react-use";
import { render, unmountComponentAtNode } from "react-dom";
import { useCallback } from "react";
import PropTypes from "prop-types";
import ty from "typy";
import styled from "styled-components";

import { Box, Spacer } from "@flexisaf/flexibull2/build/layout";
import { Text } from "@flexisaf/flexibull2/build/typo";

import { theme } from "@/style";

const colorTypeMap = {
    warning: theme.PrimaryYellow,
    danger: theme.PrimaryRed,
    reaffirmation: theme.PrimaryColor,
};

const getColorFromTypeMap = (type) => colorTypeMap[type] || theme.PrimaryColor;

const ConfirmationOverlay = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 90000;
    min-height: 100vh;
    display: grid;
    place-items: center;
    background-color: rgba(0, 0, 0, 0.5);
`;

const ConfirmationWrapper = styled.div`
    background: white;
    border-radius: 0.5em;
    padding: 2em 3em;

    & button {
        // Values based on flexibull's button component
        height: 36px;
        padding: 0 15px;
        border-radius: 5px;

        background: transparent;
        border: none;
        cursor: pointer;

        &:hover {
            background-color: ${darken(0.1, "white")};
        }
    }

    & button.is-cancel {
        border: 1px solid black;
    }
    & button.is-action {
        color: white;
        background-color: ${(p) => getColorFromTypeMap(p.type)};

        &:hover {
            background-color: ${(p) => darken(0.1, getColorFromTypeMap(p.type))};
        }
    }
`;

const containerId = "confirmation-container";
const defaultOptions = {};
const defaultWidth = "min(400px, 90%)";

(() => {
    if (!document.getElementById(containerId)) {
        const container = document.createElement("div");
        container.setAttribute("id", containerId);
        document.body.append(container);
    }
})();

function Confirmation(props) {
    const {
        cb,
        arg,
        title,
        message,
        type,
        width = defaultWidth,
        okButton = {},
        cancelButton = {},
        okText = "Confirm",
        cancelText = "Cancel",
        render,
        onClose,
    } = props;

    useKey("Escape", unmountConfirmation);

    const handleUnmount = useCallback(() => {
        ty(onClose).isFunction && onClose(arg);
        unmountConfirmation();
    }, [onClose]);

    const handleAction = useCallback(() => {
        ty(cb).isFunction && cb(arg);
        unmountConfirmation();
    }, [cb]);

    return (
        <ConfirmationOverlay>
            {ty(render).isFunction ? (
                render(handleAction, handleUnmount)
            ) : (
                <ConfirmationWrapper type={type} style={{ width: width }}>
                    <Box>
                        <Box>
                            <Text block bold size="1.5em" style={{ textAlign: "center" }}>
                                {title}
                            </Text>
                            <Spacer space="20" />
                            <Text block style={{ textAlign: "center" }}>
                                {message}
                            </Text>
                        </Box>
                        <Spacer space="20" />
                        <Box display="flex" pad="20" style={{ justifyContent: "center" }}>
                            <button
                                className="is-cancel"
                                onClick={handleUnmount}
                                style={{ marginRight: "1em", ...(cancelButton.styles || {}) }}
                            >
                                {cancelButton.text || cancelText}
                            </button>
                            <button
                                className="is-action"
                                style={okButton.styles || {}}
                                onClick={handleAction}
                            >
                                {okButton.title || okText}
                            </button>
                        </Box>
                    </Box>
                </ConfirmationWrapper>
            )}
        </ConfirmationOverlay>
    );
}

Confirmation.propTypes = {
    cb: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
    onClose: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    arg: PropTypes.any,
    render: PropTypes.func,
    type: PropTypes.oneOf(["danger", "reaffirmation", "warning"]),
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    okButton: PropTypes.shape({ style: PropTypes.object, text: PropTypes.string }),
    cancelButton: PropTypes.shape({ style: PropTypes.object, text: PropTypes.string }),
};

export function unmountConfirmation() {
    const container = document.getElementById(containerId);
    if (container) {
        unmountComponentAtNode(container);
    } else {
        // eslint-disable-next-line no-console
        console.error(`Unable to unmount component with container id: ${containerId}`);
    }
}

function confirmation(cb, options = {}) {
    return (arg) => {
        const actualOptions = { ...defaultOptions, ...options, cb, arg };
        const container = document.getElementById(containerId);
        if (container) {
            render(<Confirmation {...{ ...actualOptions }} />, container);
        }
    };
}

export default confirmation;
