import styled, { css } from "styled-components";

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalWrapper = styled.div`
    position: fixed;
    z-index: 1100;
    background-color: ${(p) => p.background || "white"};
    border-radius: 5px;
    overflow-y:scroll,
    box-shadow:${(p) => p.elevation};
    width:${(p) => p.width || "min(450px, 80%)"};    
    ${(p) =>
        !p.placedRight &&
        css`
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        `}

    ${(p) =>
        p.placedRight &&
        css`
            top: 0;
            height: 100%;
            right: 0;
        `}
`;

export const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f2f2;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`;

export const ModalFooter = styled.div`
    display: flex;
    justify-content: ${({ footerAlign }) => footerAlign || "flex-end"};
    align-items: center;
    padding: ${({ padding }) => padding || "20px"};
    background-color: #f2f2f2;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
