import { Suspense, useCallback, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useMedia } from "react-use";
import { useSelector } from "react-redux";

import { Layout } from "@flexisaf/flexibull2/build/layout";
import { Loader } from "@flexisaf/flexibull2/build/loader";

import {
    transactionLogsRoutes,
    dashboardRoutes,
    reportsRoute,
    settingsRoute,
    baseRoute,
    productIntegrationRoutes,
    usersRoutes,
    customerRoutes,
} from "@/routes/allRoutes";

import { MainViewWrapper } from "@/components";
import { breakpoints } from "@/utils/constants";

import { theme } from "@/style";

import AppHeader from "./AppHeader";
import Brand from "./Brand";
import LoaderLayout from "./LoaderLayout";
import { clearAuth, selectAuth } from "@/store/authSlice";
import { IconSettings } from "@/assets/icons/iconsax";
import store from "@/store";
import useScrollRestoration from "@/utils/hooks/useScrollRestoration";
import useAuthorization from "@/utils/hooks/useAuthorization";
import { appPrivileges } from "@/utils/appPrivileges";

const menuList = [
    {
        icon: "iconsax-grid-5",
        name: "dashboard",
        label: "Dashboard",
        navlink: dashboardRoutes.index,
        privilege: appPrivileges.readTransactions,
    },
    {
        icon: "iconsax-receive-square-2",
        name: "transaction logs",
        label: "Transaction Logs",
        navlink: transactionLogsRoutes.index,
        privilege: appPrivileges.readTransactions,
    },

    {
        icon: "iconsax-3dcube",
        name: "products",
        label: "Product Integrations",
        navlink: productIntegrationRoutes.index,
        privilege: appPrivileges.readIntegrations,
    },

    {
        icon: "iconsax-3dcube",
        name: "customers",
        label: "Customers",
        navlink: customerRoutes.index,
    },

    {
        icon: "iconsax-status-up",
        name: "report",
        label: "Report",
        navlink: reportsRoute,
        privilege: appPrivileges.readTransactions,
    },

    {
        icon: "iconsax-user",
        name: "user",
        label: "Users",
        navlink: usersRoutes.index,
    },

    {
        icon: "iconsax-setting-2",
        name: "settings",
        label: "Settings",
        navlink: settingsRoute,
    },
];

export default function AppLayout() {
    const navigate = useNavigate();
    const isWide = useMedia(breakpoints.DESKTOP);
    const { hasPrivilege } = useAuthorization();
    const { user } = useSelector(selectAuth);
    useScrollRestoration();

    const handleLogout = useCallback(() => {
        store.dispatch(clearAuth());
        navigate(baseRoute);
    }, []);

    const userMenuList = useMemo(
        () =>
            menuList.filter((menuItem) =>
                menuItem.privilege ? hasPrivilege(menuItem.privilege) : true
            ),
        [hasPrivilege]
    );

    const userActions = useMemo(() => {
        const actions = [];
        actions.push({ icon: <IconSettings />, name: "Logout", fn: handleLogout });
        return actions;
    }, [handleLogout]);

    return (
        <Layout
            theme={theme}
            withTopNav
            withSideNav
            sideNavProps={{
                float: !isWide,
                brand: <Brand />,
                menuList: userMenuList,
                background: "#fff",
            }}
            topNavProps={{ anchor: false, style: { overflow: "visible" } }}
            sideNavOpenWidth="20em"
            topNavContent={<AppHeader user={user} actions={userActions} />}
        >
            <MainViewWrapper>
                <Suspense
                    fallback={
                        <LoaderLayout>
                            <Loader speed="fast" />
                        </LoaderLayout>
                    }
                >
                    <Outlet />
                </Suspense>
            </MainViewWrapper>
        </Layout>
    );
}
