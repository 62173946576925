export const baseRoute = "/";
export const appRoute = "/app";
export const productsRoute = "/app/products";
export const paymentMerchantsRoute = "/app/payment-merchants";
export const reportsRoute = "/app/reports";
export const settingsRoute = "/app/settings";

export const authRoutes = {
    auth: "/auth",
    login: "/auth/login",
    accountConfirmation: "/auth/account-confirmation",
    forgotPassword: "/auth/forgot-password",
    passwordReset: "/auth/password-reset",
};

export const customerRoutes = {
    index: "/app/customers",
    addCustomer: "/app/customers/add-customer",
    getEditCustomer: (id = ":id") => `/app/customers/edit-customer/${id}`,
};

export const dashboardRoutes = {
    index: "/app/dashboard",
};

export const transactionLogsRoutes = {
    index: "/app/transaction-logs",
};

export const productIntegrationRoutes = {
    index: "/app/product-integrations",
    createProductIntegration: "/app/product-integrations/create-integration",
    getEditProductIntegration: (id = ":id") => `/app/product-integrations/edit/${id}`,
    getProductIntegration: (schoolId = ":id") => `/app/product-integrations/${schoolId}`,
};

export const usersRoutes = {
    index: "/app/users",
    createUser: "/app/users/create",
    getEditUser: (id = ":id") => `/app/users/edit/${id}`,
};
