import { useForm } from "react-hook-form";

import { Box } from "@flexisaf/flexibull2/build/layout";

import { HookForm as Form, Grid } from "@/components";
import Stepper, { useStepper } from "@/components/stepper/Stepper";

import { useGetSchoolQuery } from "@/api/school";
import { getSchoolAsOptions } from "@/utils/help";
import { ContentBaseWrapper } from "./productIntegrationStyles";
import { customerDetailsValidation } from "../productIntegrationValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useProductIntegration } from "../productIntegrationUtils";
import { useEffect } from "react";

export default function CustomerDetails() {
    const { data: schools, isLoading: isLoadingSchools } = useGetSchoolQuery();
    const { addToValues, warnOnUnload, toggleWarnOnUnload } = useProductIntegration();
    const form = useForm({
        resolver: yupResolver(customerDetailsValidation),
    });

    const { handleNext } = useStepper();

    useEffect(() => {
        if (form.formState.isDirty && !warnOnUnload) {
            toggleWarnOnUnload();
        }
    }, [form.formState.isDirty, warnOnUnload]);

    const onSubmit = (formBody) => {
        addToValues(formBody);
        handleNext();
    };

    return (
        <ContentBaseWrapper>
            <div className="content">
                {/* The 'content' class has 'scroll-y:auto' which hides the label of the select input,
                 * the 'Box' below is to push down the label to enable it display.
                 */}
                <Box margin="2.5em 0 0" />
                <Form form={form}>
                    <Grid style={{ maxWidth: "30em" }} gap="3.8em">
                        <Form.Input name="description" label="Description" />
                        <Form.Select
                            name="schoolId"
                            label="Name of Institution/Company"
                            options={getSchoolAsOptions(schools)}
                            disable={isLoadingSchools || !schools}
                            isLoading={isLoadingSchools}
                        />
                    </Grid>
                </Form>
            </div>
            <Stepper.StepButtons onNext={form.handleSubmit(onSubmit)} />
        </ContentBaseWrapper>
    );
}
