import axios from "axios";
import ty from "typy";
import { Mutex } from "async-mutex";

import store from "@/store";
import { clearAuth, updateAuthToken } from "@/store/authSlice";
import { authRoutes } from "@/routes/allRoutes";
import { NETWORK_ERR_CODE, NETWORK_ERR_MESSAGE, UNAUTHORIZED_ERROR } from "@/utils/constants";
import config from "@/utils/config";
import { filterFalsyValues } from "@/utils/help";

const mutex = new Mutex();

export default async function request(options) {
    try {
        await mutex.waitForUnlock();
        const response = await agent(options);
        return formatResponse(response);
    } catch (failedReq) {
        const isInvalidTokenError =
            failedReq.response?.status === 401 && Boolean(store.getState()?.auth.authToken);

        if (isInvalidTokenError) {
            if (!mutex.isLocked()) {
                await refreshAuthToken();
            }

            await mutex.waitForUnlock();
            const resp = await request(options);
            return resp;
        }

        const errorResp = handleRequestError(failedReq);
        return makeRejectedError(errorResp);
    }
}

const axiosInstance = axios.create();

const agent = (options) => {
    const { method, data, url } = options;

    const { authToken } = store.getState()?.auth || {};

    if (authToken) {
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${authToken}`;
    }

    switch (method.toLowerCase()) {
        case "get":
            return axiosInstance.get(url, { params: filterFalsyValues(data) });
        case "delete":
            return axiosInstance.delete(url, { data });
        case "post":
        case "put":
        case "patch":
            return axiosInstance({ method, url, data });
        default:
            return axiosInstance(options);
    }
};

export const handleRequestError = (requestError) => {
    if (requestError.response) {
        const { data, status } = requestError.response;

        if (status === 403) {
            return { status, message: UNAUTHORIZED_ERROR };
        }

        if (typeof data === "string") {
            return { status, message: data };
        }

        if (ty(data).isObject) {
            const message = data.message || data.error;
            return { status, message };
        }
    }

    return { status: NETWORK_ERR_CODE, message: NETWORK_ERR_MESSAGE };
};

const refreshAuthToken = async () => {
    const { refreshToken } = store.getState()?.auth;
    const refreshURL = `${config.umsURL}/oauth2/token/`;

    const formBody = new URLSearchParams();
    formBody.append("grant_type", "refresh_grant");
    formBody.append("refresh_token", refreshToken);

    const unlock = await mutex.acquire();

    try {
        const resp = await axios.post(refreshURL, formBody.toString(), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            skipAuthRefresh: true,
        });

        const { access_token: authToken } = resp?.data;
        store.dispatch(updateAuthToken(authToken));
    } catch (err) {
        store.dispatch(clearAuth());
        localStorage.clear();
        window.location.replace(authRoutes.login);
    } finally {
        unlock();
    }
};

const formatResponse = (resp) => {
    if (resp?.data) return { data: resp.data };
    return resp;
};

const makeRejectedError = (error) => Promise.reject({ error });
