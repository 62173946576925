import { theme } from "@/style";
import useAuthorization from "@/utils/hooks/useAuthorization";
import { Box } from "@flexisaf/flexibull2/build/layout";
import { Text } from "@flexisaf/flexibull2/build/typo";
import PropTypes from "prop-types";

export default function PrivilegeGuard(props) {
    const { privileges, anyOf, children } = props;
    const { hasPrivilege } = useAuthorization();

    if (!hasPrivilege(privileges, anyOf)) {
        return (
            <Box display="flex" style={{ width: "100%", height: "100%" }}>
                <Text color={theme.PrimaryRed}>
                    You are not authorized to access this resource{" "}
                </Text>
            </Box>
        );
    }
    return children;
}

PrivilegeGuard.propTypes = {
    privileges: PropTypes.arrayOf(PropTypes.string),
    anyOf: PropTypes.bool,
    children: PropTypes.node,
};
