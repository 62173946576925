import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object } from "yup";

import { Box } from "@flexisaf/flexibull2/build/layout";
import { Text } from "@flexisaf/flexibull2/build/typo";

import { HookForm as Form, Grid, Stepper } from "@/components";
import { useStepper } from "@/components/stepper/Stepper";
import { theme } from "@/style";

import { getStringSchema } from "../productIntegrationValidation";
import { ContentBaseWrapper } from "./productIntegrationStyles";
import { useProductIntegration } from "../productIntegrationUtils";
import { productOptions } from "@/utils/uiOptions";

const { CardSelector } = Form;

const validation = object({
    productId: getStringSchema("Product").required(),
});

export default function ProductDetails() {
    const { handleNext } = useStepper();
    const { addToValues } = useProductIntegration();
    const form = useForm({
        resolver: yupResolver(validation),
    });

    const onSubmit = (formBody) => {
        addToValues(formBody);
        handleNext();
    };
    const validationError = form.formState.errors?.productId?.message;
    return (
        <ContentBaseWrapper>
            <div className="content">
                <Form form={form}>
                    <Box margin="0 0 2em">
                        <Text className="title"> Select the Product you wish to integrate</Text>
                    </Box>
                    <Grid default=" 1fr / 1fr" desktop="1fr / 1fr 1fr 1fr ">
                        {productOptions.map((product) => (
                            <CardSelector
                                name="productId"
                                key={product.value}
                                label={product.label}
                                value={product.value}
                                view="label"
                            />
                        ))}
                    </Grid>
                    {validationError && <Text color={theme.PrimaryRed}> {validationError} </Text>}
                </Form>
            </div>
            <Stepper.StepButtons onNext={form.handleSubmit(onSubmit)} />
        </ContentBaseWrapper>
    );
}
