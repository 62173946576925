import { Switch as FlexiSwitch } from "@flexisaf/flexibull2/build/switch";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";

export default function Switch(props) {
    const { name, label, rules = {}, defaultChecked = false, ...restProps } = props;
    const { field, fieldState } = useController({ name, defaultValue: defaultChecked, rules });

    return (
        <FlexiSwitch
            name={name}
            label={label}
            onChange={field.onChange}
            checked={field.value}
            error={fieldState.error?.message}
            {...restProps}
        />
    );
}

Switch.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultChecked: PropTypes.string,
    rules: PropTypes.object,
};
