import PropTypes from "prop-types";

import { Loader } from "@flexisaf/flexibull2/build/loader";

import { ErrorOccured } from "@/components";

import { LoadableWrapper } from "./Loadable.styled";

const UNKNOWN_STATE = "Something unexpected occured! Can't proceed with action at this time";

const withWrapper = (styleProps, cb) => <LoadableWrapper {...styleProps}> {cb()}</LoadableWrapper>;

export default function Loadable(props) {
    const { isLoading, error, children, retry, errorComponent, ...styleProps } = props;

    if (isLoading) return withWrapper(styleProps, () => <Loader />);
    if (error)
        return errorComponent
            ? errorComponent
            : withWrapper(styleProps, () => (
                  <ErrorOccured error={error || UNKNOWN_STATE} retry={retry} />
              ));

    if (!error && !isLoading) return children;
}

Loadable.propTypes = {
    canRender: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    retry: PropTypes.func,
    children: PropTypes.node.isRequired,
    error: PropTypes.any,
    errorComponent: PropTypes.node,
};
