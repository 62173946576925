import React from "react";

import PropTypes from "prop-types";

import { Text } from "@flexisaf/flexibull2/build/typo";
import { Box } from "@flexisaf/flexibull2/build/layout";

import { DateFilter } from "@/components";
import { noop } from "@/utils/help";
import { OverViewWrapper, OverViewTitleWrapper } from "./OverViewStyles";
import { IconInfo } from "@/components/icons";
import Card from "./Card";
import { theme } from "@/style";

function OverView(props) {
    const { title, children, withFilter } = props;
    return (
        <>
            <OverViewTitleWrapper>
                <Box display="flex" style={{ alignItems: "center" }}>
                    <Text color={theme.PrimaryColor} className="title">
                        {title}
                    </Text>
                    <IconInfo className="icon" />{" "}
                </Box>

                {withFilter && <DateFilter onChange={noop} />}
            </OverViewTitleWrapper>
            <OverViewWrapper bordered itemsDashed background="#fff">
                {children}
            </OverViewWrapper>
        </>
    );
}

export default Object.assign(OverView, {
    Card,
});

OverView.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    withFilter: PropTypes.bool,
};
