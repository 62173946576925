import PropTypes from "prop-types";
import ty from "typy";
import { useClickAway } from "react-use";
import { useCallback, useEffect, useMemo, useRef } from "react";

import { Text } from "@flexisaf/flexibull2/build/typo";
import { Button } from "@flexisaf/flexibull2/build/button";
import { Box } from "@flexisaf/flexibull2/build/layout";

import { theme } from "@/style";
import { ModalWrapper, ModalHeader, ModalFooter, Backdrop } from "./PaylogModalStyles";

export default function PaylogModal(props) {
    const {
        autoClose = true,
        title,
        children,
        hideCloseButton,
        placedRight,
        background,
        footerAlign,
        onClose,
        modalStyles = {},
        width,
        closeIf,
        elevation = theme.Elevation.Lg,
        footerContents,
        padding = "2em 2.5em",
    } = props;
    const modalRef = useRef();
    const isFooterShown = useMemo(
        () => footerContents && !placedRight,
        [footerContents, !placedRight]
    );

    const handleClose = useCallback(() => {
        if (ty(onClose).isFunction) {
            onClose();
        }
    }, [onClose]);

    useEffect(() => {
        closeIf && handleClose();
    }, [handleClose, closeIf]);

    useClickAway(modalRef, () => autoClose && onClose());

    return (
        <>
            <Backdrop />
            <ModalWrapper
                background={background}
                elevation={elevation}
                placedRight={placedRight}
                style={modalStyles}
                ref={modalRef}
                width={width}
            >
                <ModalHeader style={{ padding: padding }}>
                    <Text bold size="1.6em">
                        {title}
                    </Text>
                    {!hideCloseButton && (
                        <Button plain style={{ fontSize: "2em" }} onClick={onClose}>
                            &times;
                        </Button>
                    )}
                </ModalHeader>
                <Box style={{ padding: padding }}>{children}</Box>
                {isFooterShown && (
                    <ModalFooter footerAlign={footerAlign} padding={padding}>
                        <button>Cancel</button>
                        <button>Save</button>
                    </ModalFooter>
                )}
            </ModalWrapper>
        </>
    );
}

PaylogModal.propTypes = {
    width: PropTypes.string,
    autoClose: PropTypes.bool,
    title: PropTypes.string.isRequired,
    background: PropTypes.string,
    padding: PropTypes.string,
    hideCloseButton: PropTypes.bool,
    placedRight: PropTypes.bool,
    elevation: PropTypes.string,
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool,
    modalStyles: PropTypes.object,
    footerContents: PropTypes.node,
    footerAlign: PropTypes.oneOf(["left", "center", "right"]),
    onClose: PropTypes.func,
    closeIf: PropTypes.bool,
};
