import PropTypes from "prop-types";

import { Loader } from "@flexisaf/flexibull2/build/loader";
import { StyledActionItem } from "./tableStyles";

export default function ActionItem(props) {
    const { icon, title, fn, isLoading, disabled } = props;

    const handleClick = (evt) => {
        if (disabled) return;
        fn(evt);
    };

    return isLoading ? (
        <Loader size="16" />
    ) : (
        <StyledActionItem
            title={title}
            aria-role="button"
            onClick={handleClick}
            disabled={disabled}
        >
            <span className="icon">{icon}</span>
        </StyledActionItem>
    );
}

ActionItem.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.node,
    fn: PropTypes.func,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
};
