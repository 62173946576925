import PropTypes from "prop-types";
import { Select as FlexiSelect } from "@flexisaf/flexibull2/build/select";
import ty from "typy";
import { useCallback } from "react";

const getSelectValue = (options, value, valueSelector) => {
    if (!ty(value).isNumber && !ty(value).isBoolean && !value) return "";

    if (ty(value).isObject) {
        return getValueFromObject(value);
    }

    const isNumberOrBoolOrString = ty(value).isString || ty(value).isBoolean || ty(value).isNumber;
    if (isNumberOrBoolOrString) {
        return options?.find((opt) => valueSelector(opt) === value);
    }

    if (ty(value).isArray) {
        return value.map((currentValue) => getSelectValue(options, currentValue));
    }
};

const getValueFromObject = (valueObj) => {
    if (!valueObj.label || !valueObj.value)
        console.warn("Object passed does not contain 'label' or 'value' field"); //eslint-disable-line
    return valueObj;
};

export default function Select(props) {
    const { value, options, onChange, style = {}, error, getOptionValue, ...restProps } = props;

    const valueSelector = useCallback(
        (item) => (ty(getOptionValue).isFunction ? getOptionValue(item) : item.value),
        [getOptionValue]
    );

    const handleChange = (selected) => {
        if (!selected) return null;
        if (restProps.isMulti) {
            const selectedValues = selected.map(valueSelector);
            return onChange(selectedValues, selected);
        }
        onChange(valueSelector(selected), selected);
    };

    return (
        <FlexiSelect
            value={getSelectValue(options, value, valueSelector)}
            options={options}
            onChange={handleChange}
            style={{ borderRadius: "8em", ...style }}
            error={error}
            {...restProps}
        />
    );
}

Select.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    style: PropTypes.object,
    error: PropTypes.string,
    getOptionValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.object),
};
