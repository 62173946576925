import styled from "styled-components";

export const ErrorPageWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    & .illustration-wrapper {
        width: 16.5em;
        height: 11.5em;
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    & .text {
        margin-bottom: 1.5em;
    }

    & .title {
        margin-bottom: 0.5em;
        font-size: 24px;
        color: #000;
        font-weight: bold;
    }
`;
