import { minutesToMilliseconds } from "date-fns";
import { createApi } from "@reduxjs/toolkit/query/react";

import config from "@/utils/config";
import { createBaseQuery } from "@/lib";
import { methods } from "@/utils/constants";
import cacher from "@/utils/rtkQueryCacheUtils";

export const tagType = "TRANSACTION-LOG";

const transactionLogApi = createApi({
    baseQuery: createBaseQuery({ baseURL: `${config.apiURL}/logs` }),
    tagTypes: [cacher.defaultTags, tagType],
    reducerPath: "transactionLogApi",
    endpoints: (builder) => ({
        getLogs: builder.query({
            query: (data) => ({ url: "", method: methods.GET, data }),
            providesTags: cacher.providesList(tagType, {
                nestedKey: "entities",
                idKey: "transactionRef",
            }),
            transformResponse: transformLogsResult,
            keepUnusedDataFor: minutesToMilliseconds(2),
        }),
    }),
    refetchOnMountOrArgChange: true,
});

/** Data Transformation */
function transformLogsResult(logsResult) {
    const { entities, ...meta } = logsResult;
    return { ...meta, entities };
}

export const { useGetLogsQuery } = transactionLogApi;
export default transactionLogApi;
