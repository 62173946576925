import styled from "styled-components";

export const EmptyStateWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: ${(p) => p.width};
    height: ${(p) => p.height};
    margin: auto;
    & .illustration-wrapper {
        width: 12.2em;
        height: 10em;
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    & .text {
        margin-bottom: 1em;
    }
`;

EmptyStateWrapper.defaultProps = {
    width: "25em",
    height: "auto",
};
