/* eslint-disable */
const API_URL = process.env.REACT_APP_API_URL;
const COUNTRY_API_URL = "https://restcountries.com/v2";

export default {
    apiURL: `${API_URL}`,
    countryApiURL: COUNTRY_API_URL,
    umsURL: process.env.REACT_APP_UMS_URL,
    umsProductId: process.env.REACT_APP_UMS_PRODUCT_ID,
    umsOrganisation: process.env.REACT_APP_UMS_ORGANISATION,
};
