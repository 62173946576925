import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
    authApi,
    checkoutApi,
    configurationApi,
    transactionLog,
    productApi,
    productIntegrationApi,
    customerApi,
    schoolApi,
    userApi,
    roleApi,
    countryApi,
    bank,
} from "@/api";

import { APP_PREFIX } from "@/utils/constants";
import errorMiddleware from "./errorMiddleware";
import authSlice from "./authSlice";

const rootReducer = combineReducers({
    [authSlice.name]: authSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [checkoutApi.reducerPath]: checkoutApi.reducer,
    [configurationApi.reducerPath]: configurationApi.reducer,
    [schoolApi.reducerPath]: schoolApi.reducer,
    [transactionLog.reducerPath]: transactionLog.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [productIntegrationApi.reducerPath]: productIntegrationApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [bank.reducerPath]: bank.reducer,
    [countryApi.reducerPath]: countryApi.reducer,
});

const persistConfiguration = {
    key: APP_PREFIX,
    storage,
    whitelist: [authSlice.name],
};

const persistedReducer = persistReducer(persistConfiguration, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            authApi.middleware,
            customerApi.middleware,
            checkoutApi.middleware,
            configurationApi.middleware,
            schoolApi.middleware,
            transactionLog.middleware,
            productApi.middleware,
            productIntegrationApi.middleware,
            roleApi.middleware,
            userApi.middleware,
            countryApi.middleware,
            bank.middleware,
            errorMiddleware
        ),

    /* eslint-disable-next-line */
    devTools: process.env.NODE_ENV !== "production",
});

export default store;
