import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { IconSortDown, IconSortUp } from "@/components/icons";
import { StyledTitleCell } from "./tableStyles";

function TitleCellWithSort(props) {
    const { title, field, currentSortField, currentSortDirection, onSort } = props;
    const [sortDirection, setSortDirection] = useState();

    useEffect(() => {
        if (currentSortField === field) {
            setSortDirection(currentSortDirection);
            return;
        }
        if (sortDirection) setSortDirection(null);
    }, [currentSortField, currentSortDirection]);

    return (
        <StyledTitleCell
            onClick={() => onSort({ field })}
            // It only updates sort icons' opacity if the current sort field is
            // this particular field
            fieldSortDirection={field === currentSortField ? currentSortDirection : null}
        >
            <span className="title">{title}</span>
            <div className="sort-icons-wrapper">
                <IconSortUp />
                <IconSortDown />
            </div>
        </StyledTitleCell>
    );
}

TitleCellWithSort.propTypes = {
    title: PropTypes.string,
    field: PropTypes.string,
    currentSortField: PropTypes.string,
    currentSortDirection: PropTypes.string,
    onSort: PropTypes.func,
};

export default TitleCellWithSort;
